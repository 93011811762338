import { useMemo, useRef } from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomBottomSheet from "./comm/CustomBottomSheet";
import "./Main.css";
import Map from "../comp/comm/Map";
import queryString from "query-string";
import { apiUrl, getClientIp } from "../js/server";
import Header from "./comm/Header";
import SearchContainer from "./comm/SearchContainer";
import SideMenu from "./comm/SideMenu";
import { cloneObj, fetchUserInfo, getDistanceFromLatLonInKm } from "../js/util";
import LoginComp from "./comm/LoginComp";
import { useAxios } from "../customHook/useAxios";
import useLocation from "../customHook/useLocation";
import { NOT_LOGIN_MESSAGE } from "../initialState/comInfoInitialState";
import Loading from "./comm/Loading";
import PromotionInfo from "./comm/PromotionInfo";

function Main({ match, location: routerLocation, history: routerHistory }) {
    const dispatch = useDispatch();
    const store = useSelector((state) => state);
    const query = queryString.parse(routerLocation.search);
    const q_keyword = query.keyword;

    const myRef = useRef({});
    const [isFirstAttempt, setIsFirstAttempt] = useState(true);
    const [keyword, setKeyword] = useState(q_keyword || null);
    const [isSearchMode, setIsSearchMode] = useState(false);
    const [isDetailComSelectedModel, setIsDetailComSelectedModel] = useState(false);
    const [isPopLogin, setIsPopLogin] = useState(false);
    const [selectDistance, setSelectDistance] = useState("");
    const [selectedHashTag, setSelectedHashTag] = useState([]);
    const [orgComList, setOrgComList] = useState([]);
    const [listItem, setListItem] = useState([]);
    const [myComItem, setMyComItem] = useState([]);
    const [photos, setPhotos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isPromotion, setIsPromotion] = useState(false);
    const [selectedPromotion, setSelectedPromotion] = useState(null);
    const [selectedPromotionComName, setSelectedPromotionComName] = useState(null);
    // eslint-disable-next-line
    const [mapDrag, setMapDrag] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(16);
    const [userInfo, setUserInfo] = useState({
        loginKind: null,
        token: null,
        loginDate: null,
        loginAccount: null,
    });
    const [location, updateLocation] = useLocation();
    const [showTooltip, setShowTooltip] = useState(false);

    //BottomSheet 상태
    const [open, setOpen] = useState(false);
    //지도에서 선택한 업체
    const [selectedCompanyByMap, setSelectedCompanyByMap] = useState(null);
    //리스트에서 상세보기 선택한 업체
    const [selectedCompanyByList, setSelectedCompanyByList] = useState();
    const [selectedComcode, setSelectedComcode] = useState(null);
    const [reservationHist, setReservationHist] = useState(false);
    //리스트에서 상세보기 선택한 업체
    //BottomSheet 확장 상태
    const [isExpanded, setIsExpanded] = useState(false);

    //현재위치
    const [clientIp, setClientIp] = useState(null);
    const [naverRef, setNaverRef] = useState();
    const [comcode, setComcode] = useState(match.params.comcode ?? "");

    const MIN_DISTANCE = 2500; // 최소 거리 (미터 단위)
    const DISTANCE_THRESHOLD = 1; // 거리 변경 감지 임계값

    const requestComInfoConfig = {
        url: apiUrl,
        method: "post",
        data: {
            servicename: "ShopList",
            lat: location.lat,
            long: location.lng,
            search_text: keyword,
            distance: selectDistance,
            comcode,
        }
    };

    const requestBookMarkConfig = {
        url: apiUrl,
        method: "post",
        data: {
            servicename: "Bookmark",
            platform: userInfo.loginKind,
            email: userInfo.loginAccount,
        }
    };

    const requestMyComConfig = {
        url: apiUrl,
        method: "post",
        data: {
            servicename: "BookmarkSave",
            platform: userInfo.loginKind,
            email: userInfo.loginAccount,
            comcode,
            isdel: null,
        }
    };

    const requestGeoLocationConfig = {
        url: "https://trs.intravan.co.kr/Controllers/getGeoLocation",
        method: "post",
        data: {
            ip: null,
        }
    };

    const { res, error, loading, refetch: reqComInfo } = useAxios(requestComInfoConfig);
    const { res: resBookMark, error: errorBookMark, loading: loadingBookMark, refetch: reqBookMark } = useAxios(requestBookMarkConfig);
    const { res: resMyCom, error: errorMyCom, loading: loadingMyCom, refetch: reqMyCom } = useAxios(requestMyComConfig);
    const { res: resGeoLocation, error: errorGeoLocation, loading: loadingGeoLocation, refetch: reqGeoLocation } = useAxios(requestGeoLocationConfig);

    function fetchCominfo() {
        const reqData = {
            data: {
                servicename: "ShopList",
                lat: location.lat,
                long: location.lng,
                search_text: keyword,
                distance: selectDistance,
                comcode,
            }
        }
        reqComInfo(reqData);
    }

    const setGeoLocation = (ip) => {
        const onGeoLocationSuccess = (position) => {
            const { latitude: lat, longitude: lng } = position.coords;

            // 특정 기본 위치일 경우 추가 위치 정보 요청
            if (lat === 35.907757 && lng === 127.766922) {
                fetchGeoLocation(ip);
            } else {
                // 다른 위치 처리 (필요한 경우)
                updateLocation({
                    lat, lng,
                    center: { lat, lng }
                });
            }
        };

        const onGeoLocationError = () => {
            fetchGeoLocation(ip);
        };

        const fetchGeoLocation = (ip) => {
            reqGeoLocation({ data: { ip } });
        };

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(onGeoLocationSuccess, onGeoLocationError);
        } else {
            fetchGeoLocation(ip);
        }
    };

    const setPhotosHandler = (arrPhoto) => {
        const photosArray = arrPhoto.map(photo => ({ src: photo.file_url }));
        setPhotos(photosArray);
    };

    function onDismiss() {
        setOpen(false);
    }

    const moveCenter = (targetArray) => {
        var lat = parseFloat(targetArray.gis_x_coor);
        var lng = parseFloat(targetArray.gis_y_coor);

        updateLocation({
            lat, lng, center: {
                lat, lng
            }
        });
    };

    //지도에서 업체 선택시
    function selectCompany(e, comcode) {
        try {
            e.preventDefault();
        } catch { }

        setOpen((prevState) => {
            return true;
        });

        const targetArray = listItem.filter((item) => {
            return item.comcode === comcode;
        });

        setSelectedComcode(comcode);
        setSelectedCompanyByMap(targetArray[0]);
        setPhotosHandler(targetArray[0].jarr_comphoto);
        setSelectedCompanyByList(null);
        setReservationHist(false);
        setIsExpanded(false);

        myRef.current.snapToBottom();

        //맵 센터로 이동
        //moveCenter(targetArray[0]);
    }

    function unSelectCompany() {
        //공통상태 초기화
        resetSelections();

        setPhotosHandler([]);
        setIsExpanded(false);

        myRef.current.snapToBottom();
    }

    //업체 상세보기 선택
    function detailCompanyView(comcode) {
        const targetArray = listItem.filter((item) => {
            return item.comcode === comcode;
        });

        setSelectedComcode(comcode);
        setIsExpanded(true);
        setSelectedCompanyByList(targetArray[0]);
        setReservationHist(false);
        setPhotosHandler(targetArray[0].jarr_comphoto);
        myRef.current.snapExpand();

        // 맵이동시 갱신을 막기 위한 상태
        setIsDetailComSelectedModel(true);
        // 맵 센터로 이동
        moveCenter(targetArray[0]);
    }

    const setSelectedHashTagHandler = (value, kind) => {
        if (kind === 'distance') {
            if (selectedHashTag.length < 1) {
                alert("브랜드, 전문분야 선택후 거리적용이 가능합니다.");
                return;
            }
            setSelectDistance((prev) => prev === value ? "" : value);
        } else {
            setSelectedHashTag((prevTags) => {
                const currentTag = `${kind}:${value}`;
                const exists = prevTags.includes(currentTag);

                if (exists) {
                    // 이미 존재하는 태그를 제거
                    return prevTags.filter(tag => tag !== currentTag);
                } else {
                    // 다른 유형의 태그는 유지하면서, 같은 유형의 태그가 있다면 제거
                    const filteredTags = prevTags.filter(tag => !tag.startsWith(kind + ':'));
                    // 새 태그를 추가
                    return [...filteredTags, currentTag];
                }
            });
        }
    };

    const setMapDragEndHandler = (_lat, _lng, zoomLevel) => {
        setMapDrag(zoomLevel >= 12 && zoomLevel <= 16 && !isSearchMode);
    };

    const setIsSearchModeHandler = (state, value) => {
        if (isLoading) {
            alert("화면 갱신이 끝날 때까지 기다려 주세요.");
            return;
        }
        setKeyword(value);
        setIsSearchMode(value === "" ? false : true);
        fetchCominfo();
    };

    //모바일 키보드가 화면 덮는 문제
    const keywordInputFocusHandler = (state) => {
        if (state) {
            //input focus out일 때는 bottom-sheet Open
            setOpen(true);
        } else {
            //input focus blur일 때는 bottom-sheet Open
            setOpen(false);
        }
    };

    const posInitHandler = () => {
        setIsSearchMode(false);
        setKeyword(null);
        myRef.current.snapToBottom();

        fetchAndSetClientIp();
    };

    // 현재 위치 확인후 좌표값 재설정
    const fetchAndSetClientIp = () => {
        setIsLoading(true);

        getClientIp()
            .then((res) => {
                const myIpMatch = res.data.match(new RegExp(`ip=(.*)\\n`));
                if (myIpMatch && myIpMatch.length > 0) {
                    const myIp = myIpMatch[1];
                    setClientIp(myIp);
                    setGeoLocation(myIp);
                }
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setIsLoading(false);

                if (naverRef) {
                    naverRef.setZoom(16);
                }
            });
    };

    const myComItemHandler = (comcode, isDel = "") => {
        if (userInfo.loginKind && userInfo.loginAccount) {
            const strMsg =
                isDel === "Y"
                    ? "선택하신 업체를 내 정비맛집에서 해제합니다."
                    : "선택하신 업체를 내 정비맛집으로 등록합니다.\n등록시 업체를 쉽게 찾을 수 있으며 추후 추가 될 서비스를\n손쉽게 이용 가능합니다.";
            if (window.confirm(strMsg)) {
                reqMyCom({
                    data: {
                        servicename: "BookmarkSave",
                        platform: userInfo.loginKind,
                        email: userInfo.loginAccount,
                        comcode,
                        isdel: isDel,
                    }
                });
            }
        } else {
            window.alert(NOT_LOGIN_MESSAGE);
            showTooltipHandler();
        }
    };

    //내정비맛집 선택
    const selectedMyComItem = () => {
        setSelectedCompanyByList(null);
        setSelectedCompanyByMap(null);
        setReservationHist(false);
        setIsExpanded(true);

        const item = cloneObj(myComItem);
        setListItem(item);
        myRef.current.snapExpand();
    };

    //내 예약일지 선택
    const selectedMyReservationHist = () => {
        setReservationHist(true);
        setIsExpanded(true);

        myRef.current.snapExpand();
    };

    // 공통 상태 초기화
    const resetSelections = () => {
        setSelectedComcode(null);
        setSelectedCompanyByMap(null);
        setSelectedCompanyByList(null);
        setReservationHist(false);
    }

    // 검색결과가 단건인 경우 상세보기로 이동
    const moveToSingleSearchResult = (data) => {
        setSelectedComcode(data.comcode);
        setIsExpanded(true);
        setSelectedCompanyByList(data);
        setPhotosHandler(data.jarr_comphoto);

        moveCenter(data);
        if (naverRef) {
            naverRef.setZoom(16);
        }
    }

    // 거리 계산 함수
    function calculateDistance(lat1, lng1, lat2, lng2) {
        if (!lat1 || !lng1 || !lat2 || !lng2) {
            return 0;
        }

        const distance = getDistanceFromLatLonInKm(lat1, lng1, lat2, lng2);
        return distance;
    }

    const fnCheckHashTag = () => {
        const filters = selectedHashTag.reduce((acc, tag) => {
            const [kind, codename] = tag.split(":");
            acc[kind] = codename;
            return acc;
        }, {});

        // orgComList에서 조건에 맞는 항목만 필터링
        const filteredList = orgComList.filter(item => {
            return item.jarr_setcode && Object.entries(filters).every(([kind, codename]) => {
                return item.jarr_setcode.some(code =>
                    code.kind === kind.toUpperCase() && code.codename === codename
                )
            })
        });

        return filteredList;
    }

    // 사진 가져오기
    const getComPhoto = useMemo(() => {
        return (data) => {
            let repPhoto =
                data.jarr_comphoto.length > 0
                    ? data.jarr_comphoto[0].file_url
                    : "";

            if (!repPhoto) {
                // comcode에서 마지막 숫자를 추출합니다.
                const lastDigit = data.comcode.match(/\d+$/);
                const thumbnailNumber = lastDigit ? parseInt(lastDigit[0], 10) % 10 : 0;
                repPhoto = `../images/thumbnail/jmt_thumb_${thumbnailNumber}.png`;
            }

            return repPhoto;
        };
    }, []);

    const showTooltipHandler = () => {
        if (!userInfo.loginAccount && !userInfo.loginKind) {
            setShowTooltip(true);
            const timer = setTimeout(() => {
                setShowTooltip(false);
            }, 5000); // 5초 후에 풍선 도움말을 숨깁니다.

            return () => clearTimeout(timer); // 컴포넌트가 언마운트되면 타이머를 제거합니다.
        }
    }

    useEffect(() => {
        setIsLoading(loading);
    }, [loading]);

    useEffect(() => {
        setIsLoading(loadingBookMark);
    }, [loadingBookMark]);

    useEffect(() => {
        setIsLoading(loadingMyCom);
    }, [loadingMyCom]);

    useEffect(() => {
        setIsLoading(loadingGeoLocation);
    }, [loadingGeoLocation])

    useEffect(() => {
        if (orgComList.length > 0 && selectedHashTag.length > 0) {
            const filteredList = fnCheckHashTag();
            setListItem(filteredList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgComList])

    // 업체목록 조회 처리 결과
    useEffect(() => {
        if (error) {
            console.error("reqComInfo: " + error.message);
            return;
        }
        if (res && res.data.result === "OK") {
            var _data = res.data;
            const noResults = _data.jsonData.length === 0;
            // 검색 결과가 없는 경우
            if (noResults && isSearchMode) {
                alert("검색결과가 없습니다. 다른 키워드로 검색해 보세요!");
                setIsSearchMode(false);
                setKeyword(null);

                return;
            }

            // 공통 상태 초기화
            resetSelections();

            // 업체 목록 및 키워드 설정
            if (!noResults) {
                setOrgComList(_data.jsonData);
                setListItem(_data.jsonData);
            }

            if (_data.jsonData.length > 0) {
                updateLocation({
                    byRefetch: {
                        lat: parseFloat(_data.jsonData[0].gis_x_coor), lng: parseFloat(_data.jsonData[0].gis_y_coor)
                    }
                });
            }

            //검색결과 단건인 경우
            if (_data.jsonData.length === 1) {
                moveToSingleSearchResult(_data.jsonData[0]);
            }

            // 로그인 첫 화면에서만 등록한 정비맛집 기본 노출
            if (myComItem.length > 0 && isFirstAttempt) {
                const item = cloneObj(myComItem);
                setListItem(item);
                setIsFirstAttempt(false);

                updateLocation({
                    center: {
                        lat: parseFloat(_data.jsonData[0].gis_x_coor), lng: parseFloat(_data.jsonData[0].gis_y_coor)
                    }
                });
            }
        } else if (res) {
            alert("reqComInfo: " + res.data.errMsg);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [res, error]);

    // 내 정비맛집 조회
    useEffect(() => {
        if (errorBookMark) {
            console.error("reqBookMark: " + errorBookMark.message);
            return;
        }

        if (resBookMark) {
            if (resBookMark.data.result !== "OK") {
                alert("reqComInfo: " + resBookMark.data.errMsg);
                return;
            }

            var _data = resBookMark.data;
            setMyComItem(_data.jsonData);

            if (_data.jsonData.length > 0) {
                const item = cloneObj(myComItem);
                setListItem(item);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resBookMark, errorBookMark]);

    // 내 정비맛집 편집
    useEffect(() => {
        if (errorMyCom) {
            console.error("reqMyCom: " + errorMyCom.message);
            return;
        }

        if (resMyCom) {
            if (resMyCom.data.result !== "OK") {
                alert("reqComInfo: " + resMyCom.data.errMsg);
                return;
            }

            reqBookMark();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resMyCom, errorMyCom]);

    // IP 조회
    useEffect(() => {
        if (errorGeoLocation) {
            console.error("reqGeoLocation: " + errorGeoLocation.message);
        }

        if (resGeoLocation) {
            if (resGeoLocation.data.result !== "OK") {
                alert("reqComInfo: " + resGeoLocation.data.errMsg);
                return;
            }

            var { lat, long: lng } = resGeoLocation.data;

            updateLocation({
                lat, lng, center: {
                    lat, lng
                },
                currentLocation: {
                    lat, lng
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resGeoLocation, errorGeoLocation]);

    useEffect(() => {
        // 로그인 상태의 경우 내 정비맛집 데이터 조회
        if (userInfo.loginAccount && userInfo.loginKind) {
            reqBookMark();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo]);

    // 검색범위 변경시
    useEffect(() => {
        if (!location || !location.lat || !location.lng) {
            return;
        }
        fetchCominfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectDistance])

    // 해쉬태그 선택시
    useEffect(() => {
        if (selectedHashTag.length > 0) {
            const filteredList = fnCheckHashTag();
            setListItem(filteredList);
        } else {
            setListItem(orgComList);
            setSelectDistance("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedHashTag]);

    useEffect(() => {
        // 위치 정보가 없거나 검색 모드가 활성화된 경우 요청을 중단
        if (!location || !location.lat || !location.lng || isSearchMode) {
            return;
        }

        // 상세 업체가 선택된 경우 요청 중단
        if (isDetailComSelectedModel) {
            setIsDetailComSelectedModel(false);
            return;
        }

        // 이전 위치와 현재 위치 간의 거리 계산
        // byRefetch (데이터가 로드된 시점 center업체의 위치. 해당 위치를 기준으로 ??km 이동되었을 때 데이터 갱신을 기준으로 삼음.)
        const distance = calculateDistance(
            location.byRefetch?.lat,
            location.byRefetch?.lng,
            location.center.lat,
            location.center.lng
        );

        // 이동 거리가 임계값 내에 있는 경우 요청 중단
        if (distance > DISTANCE_THRESHOLD && distance < MIN_DISTANCE) {
            return;
        }

        const reqData = {
            data: {
                servicename: "ShopList",
                lat: location.lat,
                long: location.lng,
                search_text: keyword,
                distance: selectDistance,
                comcode,
            }
        }

        reqComInfo(reqData);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.lat, location.lng, isSearchMode]);

    useEffect(() => {
        setUserInfo(fetchUserInfo());
        dispatch({ type: "loadingChange", payload: false });
        setOpen(true);

        //현재 위치 확인
        fetchAndSetClientIp();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Loading
                loading={isLoading}
            />

            <div className="parent-container">
                {/* 사이드메뉴 */}
                <SideMenu
                    isMenu={store.rdcIsMenu}
                    setUserInfo={setUserInfo}
                    userInfo={userInfo}
                    selectedMyComItem={selectedMyComItem}
                    selectedMyReservationHist={selectedMyReservationHist}
                    myComItem={myComItem}
                />

                {/* 데스크탑 대응 검색창 */}
                <SearchContainer
                    keyword={keyword}
                    setKeyword={setKeyword}
                    setSelectedHashTagHandler={setSelectedHashTagHandler}
                    selectedHashTag={selectedHashTag}
                    distance={selectDistance}
                    fetchCominfo={fetchCominfo}
                    setIsSearchModeHandler={setIsSearchModeHandler}
                    isLoading={isLoading}
                    setUserInfo={setUserInfo}
                    userInfo={userInfo}
                />

                <div className="mob-container">
                    {isPromotion ? (
                        <PromotionInfo
                            setIsPromotion={setIsPromotion}
                            selectedPromotion={selectedPromotion}
                            selectedPromotionComName={selectedPromotionComName}
                        />
                    ) : null}

                    {isPopLogin ? (
                        <LoginComp
                            isPopLogin={isPopLogin}
                            setIsPopLogin={setIsPopLogin}
                            setUserInfo={setUserInfo}
                            userInfo={userInfo}
                        />
                    ) : null}

                    {/* Header메뉴 */}
                    <Header
                        isPopLogin={isPopLogin}
                        setIsPopLogin={setIsPopLogin}
                        setUserInfo={setUserInfo}
                        userInfo={userInfo}
                        posInitHandler={posInitHandler}
                        showTooltip={showTooltip}
                        showTooltipHandler={showTooltipHandler}
                    ></Header>

                    <div className="mob-contents">
                        <div className="main-container">
                            <Map
                                selectCompany={selectCompany}
                                selectedCompanyByMap={selectedCompanyByMap}
                                selectedCompanyByList={selectedCompanyByList}
                                selectedComcode={selectedComcode}
                                location={location}
                                updateLocation={updateLocation}
                                clientIp={clientIp}
                                keyword={keyword}
                                setKeyword={setKeyword}
                                setSelectedHashTagHandler={setSelectedHashTagHandler}
                                selectedHashTag={selectedHashTag}
                                distance={selectDistance}
                                listItem={listItem}
                                setNaverRef={setNaverRef}
                                naverRef={naverRef}
                                setMapDragEndHandler={setMapDragEndHandler}
                                setIsSearchModeHandler={setIsSearchModeHandler}
                                keywordInputFocusHandler={keywordInputFocusHandler}
                                open={open}
                                posInitHandler={posInitHandler}
                                setUserInfo={setUserInfo}
                                userInfo={userInfo}
                                setZoomLevel={setZoomLevel}
                                zoomLevel={zoomLevel}
                                isLoading={isLoading}
                                isPromotion={isPromotion}
                                setIsPromotion={setIsPromotion}
                            />

                            <CustomBottomSheet
                                ref={myRef}
                                open={open}
                                onDismiss={onDismiss}
                                setOpen={setOpen}
                                selectedCompanyByMap={selectedCompanyByMap}
                                setSelectedCompanyByMap={setSelectedCompanyByMap}
                                selectedCompanyByList={selectedCompanyByList}
                                reservationHist={reservationHist}
                                setReservationHist={setReservationHist}
                                isExpanded={isExpanded}
                                setIsExpanded={setIsExpanded}
                                detailCompanyView={detailCompanyView}
                                selectCompany={selectCompany}
                                unSelectCompany={unSelectCompany}
                                listItem={listItem}
                                photos={photos}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                location={location}
                                setUserInfo={setUserInfo}
                                userInfo={userInfo}
                                myComItem={myComItem}
                                fetchMyComItem={reqBookMark}
                                myComItemHandler={myComItemHandler}
                                getComPhoto={getComPhoto}
                                setIsPromotion={setIsPromotion}
                                setSelectedPromotion={setSelectedPromotion}
                                setSelectedPromotionComName={setSelectedPromotionComName}
                                showTooltipHandler={showTooltipHandler}
                            />

                            <div className="main-footer">
                                <ul className="footer-container">
                                    <li>
                                        <div>
                                            <img
                                                src="../images/iv_logo02_xhdpi.png"
                                                alt="bottom-logo"
                                                className="bottom-logo"
                                            />
                                        </div>

                                        <div>
                                            <a
                                                href="https://bit.ly/3RyFHHG"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img
                                                    src="../images/ico_blog01_xhdpi.png"
                                                    alt="blog"
                                                    className="sns-icon"
                                                    style={{ marginRight: "10px" }}
                                                />
                                            </a>

                                            <a
                                                href="https://bit.ly/3EevYTX"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img
                                                    src="../images/ico_cafe01_xhdpi.png"
                                                    alt="cafe"
                                                    className="sns-icon"
                                                    style={{ marginRight: "10px" }}
                                                />
                                            </a>

                                            <a
                                                href="https://bit.ly/3M2mvRq"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img
                                                    src="../images/ico_youtube01_xhdpi.png"
                                                    alt="youtube"
                                                    className="sns-icon"
                                                />
                                            </a>
                                        </div>
                                    </li>
                                    <li>
                                        <img
                                            src="../images/ico_chat01_xhdpi.png"
                                            alt="chat"
                                            className="chat-icon"
                                        />
                                        &nbsp; 제휴문의 : admin@intravan.com
                                    </li>
                                    <li>
                                        <img
                                            src="../images/ico_chat01_xhdpi.png"
                                            alt="chat"
                                            className="chat-icon"
                                        />
                                        &nbsp; 기술문의 : rla100@intravan.com
                                    </li>
                                    <li>
                                        [우 12925] 경기도 하남시 미사대로 520
                                        <br />
                                        현대지식산업센터 한강미사 2차 C동 713호
                                    </li>
                                    <li>사업자등록번호 : 215-81-90952 대표전화 : 1522-3840</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Main;
