import React, { useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from 'date-fns/locale';
import { reqData } from '../js/server';
import { SERVER_ERROR_MESSAGE, workTime } from '../initialState/comInfoInitialState';
import { formatDate, setTimeFormat } from '../js/util';

function ConfigTab4(props) {
    const [startOffTime, setStartOffTime] = useState("");
    const [endOffTime, setEndOffTime] = useState("");
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isSaveConfirmReservTime, setIsSaveConfirmReservTime] = useState(true);
    const [isSaveConfirmOffDay, setIsSaveConfirmOffDay] = useState(true);
    const [isSaveConfirmOffDayDelete, setIsSaveConfirmOffDayDelete] = useState(true);


    const createReservationTimeMetrix = async () => {
        if (window.confirm("예약 가능 시간을 생성하면, 설정된 예약 시간으로 새로 생성됩니다.\n생성 후에는 예약 불가 시간을 다시 설정해야 하며\n이미 접수된 정비 예약은 영향을 받지 않습니다.\n\n계속하시겠습니까?")) {
            const jsonData = {
                "servicename": "ReservTimeCreate",
                "comcode": props.comInfo.comcode,
                "set_reserv_day_usecnt": props.comInfo.set_reserv_day_usecnt,
                "set_reserv_day_time": props.comInfo.set_reserv_day_time
            };

            try {
                const res = await reqData(jsonData);
                if (res.data.result === "OK") {
                    props.updateComInfo({ jarr_reserv_dayset: [res.data.jsonData.jarr_reserv_dayset[0]] });
                    alert("완료되었습니다.\n예약 가능/불가 설정을 다시 선택해 주세요.");
                } else {
                    alert("오류가 발생했습니다: " + res.data.errMsg);
                }
            } catch (err) {
                console.error("오류 발생: ", err);
                alert(SERVER_ERROR_MESSAGE);
            }
        }
    }

    const changeReservState = (e) => {
        const id = e.currentTarget.id; // 예: "mon"
        const index = parseInt(e.currentTarget.getAttribute('data-index'), 10); // 문자열을 숫자로 변환

        // jarr_reserv_dayset의 첫 번째 요소와 관련된 배열에 접근
        let daySetArray = props.comInfo.jarr_reserv_dayset[0]["jarr_" + id];

        // 조건에 따라 set_reserv 값을 변경,배열의 불변성을 유지하기 위해 배열을 복사
        const updatedDaySetArray = [...daySetArray];
        updatedDaySetArray[index] = {
            ...updatedDaySetArray[index],
            set_reserv: updatedDaySetArray[index].set_reserv === '1' ? '0' : '1' // 값을 반전
        };

        // 전체 comInfo를 업데이트하기 위해 불변성을 유지하며 새로운 객체를 생성
        const updatedComInfo = {
            ...props.comInfo,
            jarr_reserv_dayset: [
                {
                    ...props.comInfo.jarr_reserv_dayset[0],
                    ["jarr_" + id]: updatedDaySetArray // 변경된 배열을 새 객체에 할당
                }
            ]
        };

        // 업데이트된 comInfo를 상태 업데이트 함수에 전달
        props.updateComInfo(updatedComInfo);

        if (isSaveConfirmReservTime) {
            alert("예약가능 상태 변경 후 페이지 하단 \"저장\" 버튼을 이용하여 저장을 해야 적용됩니다.");
            setIsSaveConfirmReservTime(false);
        }
    }

    const setOffSetClick = () => {
        if (!selectedDate) {
            alert("개별 휴일로 설정하실 날짜를 선택해 주세요.");
            return;
        }

        const comcode = props.comInfo.comcode;
        const holiday = formatDate(selectedDate); // selectedDate를 "yyyy-MM-dd" 형식으로 변환
        const reservOffsetItem = { comcode, holiday, start_offtime: startOffTime, end_offtime: endOffTime };

        // 이미 들어있는 데이터인지 확인
        const isExisting = props.comInfo.jarr_reserv_offset.some(item => item.holiday === holiday);

        if (isExisting) {
            alert("이미 선택된 날짜입니다.");
            return;
        }

        // 중복되지 않는 경우, 새로운 데이터를 추가
        const updatedComInfo = {
            ...props.comInfo,
            jarr_reserv_offset: [
                reservOffsetItem,
                ...props.comInfo.jarr_reserv_offset
            ]
        };

        props.updateComInfo(updatedComInfo);

        if (isSaveConfirmOffDay) {
            alert("개별 휴일 설정 후 페이지 하단 \"저장\" 버튼을 이용하여 저장을 해야 적용됩니다.");
            setIsSaveConfirmOffDay(false);
        }
    }

    const handleSelectChange = (event) => {
        const selected = Array.from(event.target.selectedOptions).map(option => option.value);
        setSelectedOptions(selected);
    };

    const handleDeleteClick = () => {
        const updatedOffsets = props.comInfo.jarr_reserv_offset.filter((item, idx) =>
            !selectedOptions.includes(idx.toString()) // 선택되지 않은 항목만 필터링
        );

        // comInfo 업데이트
        const updatedComInfo = {
            ...props.comInfo,
            jarr_reserv_offset: updatedOffsets
        };

        props.updateComInfo(updatedComInfo);

        if (isSaveConfirmOffDayDelete) {
            alert("개별 휴일 삭제 후 페이지 하단 \"저장\" 버튼을 이용하여 저장을 해야 적용됩니다.");
            setIsSaveConfirmOffDayDelete(false);
        }
    };

    return (
        <>
            <div className="row pt-4">
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="comname" className="config-title">
                            예약 기능을 활성화 시키시겠습니까? (해당 기능 사용시 고객이
                            정비맛집에서 예약이 가능합니다.)
                        </label>
                        <div className="custom-control custom-switch">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="set_reserv"
                                checked={props.comInfo.set_reserv === "1"}
                                onChange={(e) => {
                                    props.updateComInfo({ set_reserv: e.target.checked ? "1" : "0" });
                                }}
                            />
                            {props.comInfo.set_reserv === "1" ? (
                                <label
                                    className="custom-control-label blue-text"
                                    htmlFor="set_reserv"
                                    id="set_reservLabel"
                                >
                                    예약기능을 사용하겠습니다!
                                </label>
                            ) : (
                                <label
                                    className="custom-control-label"
                                    htmlFor="set_reserv"
                                    id="set_reservLabel"
                                >
                                    아쉽지만 예약기능이 필요하지 않습니다.
                                </label>
                            )}
                        </div>
                        <small id="set_reservHelp" className="form-text blue-text">
                            * 필수
                        </small>
                    </div>
                </div>
            </div>

            {props.comInfo.set_reserv === "1" ? (
                <div className="row pt-4">
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="comname" className="config-title">
                                당일예약도 가능하신가요? (해당 기능 사용시 고객이 당일예약도
                                가능합니다.)
                            </label>
                            <div className="custom-control custom-switch">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="set_day_reserv"
                                    checked={props.comInfo.set_day_reserv === "1"}
                                    onChange={(e) => {
                                        props.updateComInfo({ set_day_reserv: e.target.checked ? "1" : "0" });
                                    }}
                                />
                                {props.comInfo.set_day_reserv === "1" ? (
                                    <label
                                        className="custom-control-label blue-text"
                                        htmlFor="set_day_reserv"
                                        id="set_day_reservLabel"
                                    >
                                        당일예약도 가능합니다!
                                    </label>
                                ) : (
                                    <label
                                        className="custom-control-label"
                                        htmlFor="set_day_reserv"
                                        id="set_day_reservLabel"
                                    >
                                        아쉽지만 당일예약은 어렵네요...
                                    </label>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            <div className="row pt-4">
                <div className="col-12">
                    <div className="form-group">
                        <span className="config-title">정비 예약 상세 설정</span>
                        <br />
                        * 예약 시간 간격과 정비소에서 동시에 처리할 수 있는 차량의 수를 정할 수 있습니다.
                        <br />
                        &nbsp;&nbsp;&nbsp;ex) 만약 예약 간격을 30분으로, 처리 가능 차량 대수를 2대로 설정한다면, 정비소는 30분마다 최대 2대의 차량 예약을 받을 수 있게 됩니다.
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-2">
                    <div className="working-time-wrapper bg-light">예약 간격 설정</div>
                </div>
                <div className="col-4">
                    <select
                        className="form-control work-select-input"
                        id="set_reserv_day_time"
                        aria-describedby="set_reserv_day_time"
                        value={props.comInfo.set_reserv_day_time || ""}
                        onChange={props.setCominfoHandler}>
                        <option value="30">30분</option>
                        <option value="60">60분</option>
                    </select>
                </div>
                <div className="col-2">
                    <div className="working-time-wrapper bg-light">처리 가능 차량 대수</div>
                </div>
                <div className="col-4">
                    <select
                        className="form-control work-select-input"
                        id="set_reserv_day_usecnt"
                        aria-describedby="set_reserv_day_usecnt"
                        value={props.comInfo.set_reserv_day_usecnt || ""}
                        onChange={props.setCominfoHandler}
                    >
                        <option value="1">1대</option>
                        <option value="2">2대</option>
                        <option value="3">3대</option>
                    </select>
                </div>
            </div>

            <div className="row pt-4">
                <div className="col-12 text-center">
                    <button className="btn btn-primary pr-5 pl-5" onClick={createReservationTimeMetrix}>
                        <i className="fa-sharp fa-solid fa-wand-magic-sparkles pr-2" />
                        예약 가능 시간 생성
                    </button>
                </div>
            </div>

            <div className="row pt-4">
                <div className="col-6">* 해당 시간을 선택하시면 예약 가능/불가 상태로 변경됩니다.</div>
                <div className="col-6 text-right">
                    <i className="fa-sharp fa-solid fa-circle-check text-primary pr-1" />
                    <span className="pr-3 text-primary font-weight-bold">예약가능</span>
                    <i className="fa-solid fa-circle-x text-danger pr-1" />
                    <span className="pr-3 text-danger font-weight-bold">예약불가</span>
                </div>
            </div>

            <div className="row">
                <div className="col p-1">
                    <div className="working-time-wrapper bg-light">월</div>
                    <ul>
                        {
                            (props.comInfo.jarr_reserv_dayset[0].jarr_mon)
                                ?
                                props.comInfo.jarr_reserv_dayset[0].jarr_mon.map((item, idx) => {
                                    return (
                                        <li
                                            id="mon"
                                            className="reserv-time"
                                            key={idx}
                                            data-index={idx}
                                            onClick={(e) => changeReservState(e)}
                                        >
                                            {setTimeFormat(item.set_time) ?? ""}
                                            {
                                                item.set_reserv === "1"
                                                    ? <i className="pl-1 fa-sharp fa-solid fa-circle-check text-primary " />
                                                    : <i className="pl-1 fa-solid fa-circle-x text-danger " />
                                            }
                                        </li>
                                    )
                                })
                                : <li id="mon" className="reserv-time" style={{ cursor: 'default' }}>공휴일<i className="pl-1 fa-solid fa-circle-x text-danger " /></li>
                        }
                    </ul>
                </div>

                <div className="col p-1">
                    <div className="working-time-wrapper bg-light">화</div>
                    <ul>
                        {
                            (props.comInfo.jarr_reserv_dayset[0].jarr_tue)
                                ?
                                props.comInfo.jarr_reserv_dayset[0].jarr_tue.map((item, idx) => {
                                    return (
                                        <li
                                            id="tue"
                                            className="reserv-time"
                                            key={idx}
                                            data-index={idx}
                                            onClick={(e) => changeReservState(e)}
                                        >
                                            {setTimeFormat(item.set_time) ?? ""}
                                            {
                                                item.set_reserv === "1"
                                                    ? <i className="pl-1 fa-sharp fa-solid fa-circle-check text-primary " />
                                                    : <i className="pl-1 fa-solid fa-circle-x text-danger " />
                                            }
                                        </li>
                                    )
                                })
                                : <li id="tue" className="reserv-time" style={{ cursor: 'default' }}>공휴일<i className="pl-1 fa-solid fa-circle-x text-danger " /></li>
                        }
                    </ul>
                </div>

                <div className="col p-1">
                    <div className="working-time-wrapper bg-light">수</div>
                    <ul>
                        {
                            (props.comInfo.jarr_reserv_dayset[0].jarr_wed)
                                ?
                                props.comInfo.jarr_reserv_dayset[0].jarr_wed.map((item, idx) => {
                                    return (
                                        <li
                                            id="wed"
                                            className="reserv-time"
                                            key={idx}
                                            data-index={idx}
                                            onClick={(e) => changeReservState(e)}
                                        >
                                            {setTimeFormat(item.set_time) ?? ""}
                                            {
                                                item.set_reserv === "1"
                                                    ? <i className="pl-1 fa-sharp fa-solid fa-circle-check text-primary " />
                                                    : <i className="pl-1 fa-solid fa-circle-x text-danger " />
                                            }
                                        </li>
                                    )
                                })
                                : <li id="web" className="reserv-time" style={{ cursor: 'default' }}>공휴일<i className="pl-1 fa-solid fa-circle-x text-danger " /></li>
                        }
                    </ul>
                </div>

                <div className="col p-1">
                    <div className="working-time-wrapper bg-light">목</div>
                    <ul>
                        {
                            (props.comInfo.jarr_reserv_dayset[0].jarr_thu)
                                ?
                                props.comInfo.jarr_reserv_dayset[0].jarr_thu.map((item, idx) => {
                                    return (
                                        <li
                                            id="thu"
                                            className="reserv-time"
                                            key={idx}
                                            data-index={idx}
                                            onClick={(e) => changeReservState(e)}
                                        >
                                            {setTimeFormat(item.set_time) ?? ""}
                                            {
                                                item.set_reserv === "1"
                                                    ? <i className="pl-1 fa-sharp fa-solid fa-circle-check text-primary " />
                                                    : <i className="pl-1 fa-solid fa-circle-x text-danger " />
                                            }
                                        </li>
                                    )
                                })
                                : <li id="thu" className="reserv-time" style={{ cursor: 'default' }}>공휴일<i className="pl-1 fa-solid fa-circle-x text-danger " /></li>
                        }
                    </ul>
                </div>

                <div className="col p-1">
                    <div className="working-time-wrapper bg-light">금</div>
                    <ul>
                        {
                            (props.comInfo.jarr_reserv_dayset[0].jarr_fri)
                                ?
                                props.comInfo.jarr_reserv_dayset[0].jarr_fri.map((item, idx) => {
                                    return (
                                        <li
                                            id="fri"
                                            className="reserv-time"
                                            key={idx}
                                            data-index={idx}
                                            onClick={(e) => changeReservState(e)}
                                        >
                                            {setTimeFormat(item.set_time) ?? ""}
                                            {
                                                item.set_reserv === "1"
                                                    ? <i className="pl-1 fa-sharp fa-solid fa-circle-check text-primary " />
                                                    : <i className="pl-1 fa-solid fa-circle-x text-danger " />
                                            }
                                        </li>
                                    )
                                })
                                : <li id="fri" className="reserv-time" style={{ cursor: 'default' }}>공휴일<i className="pl-1 fa-solid fa-circle-x text-danger " /></li>
                        }
                    </ul>
                </div>

                <div className="col p-1">
                    <div className="working-time-wrapper bg-light">토</div>
                    <ul>
                        {
                            (props.comInfo.jarr_reserv_dayset[0].jarr_sat)
                                ?
                                props.comInfo.jarr_reserv_dayset[0].jarr_sat.map((item, idx) => {
                                    return (
                                        <li
                                            id="sat"
                                            className="reserv-time"
                                            key={idx}
                                            data-index={idx}
                                            onClick={(e) => changeReservState(e)}>
                                            {setTimeFormat(item.set_time) ?? ""}
                                            {
                                                item.set_reserv === "1"
                                                    ? <i className="pl-1 fa-sharp fa-solid fa-circle-check text-primary " />
                                                    : <i className="pl-1 fa-solid fa-circle-x text-danger " />
                                            }
                                        </li>
                                    )
                                })
                                : <li id="sat" className="reserv-time" style={{ cursor: 'default' }}>공휴일<i className="pl-1 fa-solid fa-circle-x text-danger " /></li>
                        }
                    </ul>
                </div>

                <div className="col p-1">
                    <div className="working-time-wrapper bg-light">일</div>
                    <ul>
                        {
                            (props.comInfo.jarr_reserv_dayset[0].jarr_sun)
                                ?
                                props.comInfo.jarr_reserv_dayset[0].jarr_sun.map((item, idx) => {
                                    return (
                                        <li
                                            id="sun"
                                            className="reserv-time"
                                            key={idx}
                                            data-index={idx}
                                            onClick={(e) => changeReservState(e)}
                                        >
                                            {setTimeFormat(item.set_time) ?? ""}
                                            {
                                                item.set_reserv === "1"
                                                    ? <i className="pl-1 fa-sharp fa-solid fa-circle-check text-primary " />
                                                    : <i className="pl-1 fa-solid fa-circle-x text-danger " />
                                            }
                                        </li>
                                    )
                                })
                                : <li id="sun" className="reserv-time" style={{ cursor: 'default' }}>공휴일<i className="pl-1 fa-solid fa-circle-x text-danger " /></li>
                        }
                    </ul>
                </div>
            </div>

            <div className="row pt-4">
                <div className="col-12">
                    <div className="form-group">
                        <span className="config-title">개별 휴무일 및 예약불가 일시 설정</span>
                        <br />
                        * 운영 일정에 맞춰 개별 휴무일을 설정하실 수 있습니다.
                        <br />
                        &nbsp;&nbsp;&nbsp;종일로 설정된 휴무일은 당일날 고객에게 "금일 휴무"라는 안내 문구가 함께 표시되며, 해당일에는 예약이 불가능합니다.
                        <br />
                        &nbsp;&nbsp;&nbsp;개별 시간으로 설정하는 경우 해당 일자의 설정 시간에는 "예약 불가" 상태가 됩니다.
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-2">
                    <div className="working-time-wrapper bg-light">개별 휴일 설정</div>
                </div>
                <div className="col-5">
                    <div className="input-group">
                        <DatePicker
                            name="selectedDate"
                            id="selectedDate"
                            locale={ko}
                            dateFormat="yyyy-MM-dd"
                            className="form-control col"
                            minDate={new Date()}
                            closeOnScroll={true}
                            placeholderText="개별 휴일"
                            autoComplete="off"
                            selected={selectedDate}
                            onChange={(date) => {
                                setSelectedDate(date);
                            }}
                        />
                        <select
                            className="form-control work-select-input col"
                            id="start_offtime"
                            aria-describedby="start_offtime"
                            value={startOffTime || ""}
                            onChange={(e) => setStartOffTime(e.target.value)}
                        >
                            <option value=""></option>
                            {
                                workTime.map((item, idx) => {
                                    return <option key={idx} value={item}>{item}시 부터</option>
                                })
                            }
                        </select>
                        <select
                            className="form-control work-select-input col"
                            id="end_offtime"
                            aria-describedby="end_offtime"
                            value={endOffTime || ""}
                            onChange={(e) => setEndOffTime(e.target.value)}
                        >
                            <option value=""></option>
                            {
                                workTime.map((item, idx) => {
                                    return <option value={item} key={idx}>{item}시 까지</option>
                                })
                            }
                        </select>
                        <div className="input-group-append">
                            <button className="btn btn-primary" type="button" onClick={setOffSetClick}>추가</button>
                        </div>
                    </div>
                </div>
                <div className="col-5 ml-0 pl-0 pt-1">
                    ※ <span className="font-weight-bold">00시</span> 부터 <span className="font-weight-bold">24시</span> 까지 설정시 해당일 <span className="font-weight-bold">종일</span> 휴일로 설정
                </div>
            </div>

            <div className="row pt-2">
                <div className="col-2">
                    <div className="working-time-wrapper bg-light">지정된 개별 휴일</div>
                </div>
                <div className="col-5">
                    <select multiple className="form-control" onChange={handleSelectChange}>
                        {props.comInfo.jarr_reserv_offset.map((item, idx) => {
                            const optionText = item.start_offtime === "00" && item.end_offtime === "24"
                                ? `${item.holiday} 종일`
                                : `${item.holiday} ${item.start_offtime}시 부터 ${item.end_offtime}시 까지`;

                            return <option key={idx} value={idx}>{optionText}</option>;
                        })}
                    </select>

                    <button className="btn btn-primary col-12 mt-1" type="button" onClick={handleDeleteClick}>
                        삭제
                    </button>
                </div>
            </div>

            <div className="row pt-5">
                <div className="col-12">
                    <div className="form-group">
                        <span className="config-title">예약 알림 연락처를 설정해 주세요.</span>
                        <br />
                        * 솔루션 메뉴 "사용자 관리"에 등록된 사용자에게 기본 전달되며 추가 연락처 등록이 가능합니다. (최대 3개)
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-4">
                    <div className="input-group">
                        <select
                            type="text"
                            className="form-control"
                            id="set_reserv_hp0_1"
                            aria-describedby="set_reserv_hp0_1"
                            value={props.comInfo.set_reserv_hp0_1}
                            onChange={props.setCominfoHandler}
                        >
                            <option value=""></option>
                            <option value="010">010</option>
                            <option value="011">011</option>
                        </select>
                        <div className="input-group-append">
                            <input
                                type="number"
                                className="form-control"
                                id="set_reserv_hp1_1"
                                aria-describedby="set_reserv_hp1_1"
                                value={props.comInfo.set_reserv_hp1_1}
                                onChange={props.setCominfoHandler}
                            />
                        </div>
                    </div>
                    <small id="tel0_0Help" className="form-text blue-text">
                        * 필수 (숫자만 입력해 주세요.)
                    </small>
                </div>
                <div className="col-4">
                    <div className="form-group">
                        <div className="input-group">
                            <select
                                type="text"
                                className="form-control"
                                id="set_reserv_hp0_2"
                                aria-describedby="set_reserv_hp0_2"
                                value={props.comInfo.set_reserv_hp0_2}
                                onChange={props.setCominfoHandler}
                            >
                                <option value=""></option>
                                <option value="010">010</option>
                                <option value="011">011</option>
                            </select><div className="input-group-append">
                                <input
                                    type="number"
                                    className="form-control"
                                    id="set_reserv_hp1_2"
                                    aria-describedby="set_reserv_hp1_2"
                                    value={props.comInfo.set_reserv_hp1_2}
                                    onChange={props.setCominfoHandler}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="form-group">
                        <div className="input-group">
                            <select
                                type="text"
                                className="form-control"
                                id="set_reserv_hp0_3"
                                aria-describedby="set_reserv_hp0_3"
                                value={props.comInfo.set_reserv_hp0_3}
                                onChange={props.setCominfoHandler}
                            >
                                <option value=""></option>
                                <option value="010">010</option>
                                <option value="011">011</option>
                            </select>
                            <div className="input-group-append">
                                <input
                                    type="number"
                                    className="form-control"
                                    id="set_reserv_hp1_3"
                                    aria-describedby="set_reserv_hp1_3"
                                    value={props.comInfo.set_reserv_hp1_3}
                                    onChange={props.setCominfoHandler} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row pt-2">
                {
                    props.comInfo.jarr_prg_userinfo.map((item, idx) => {
                        return (
                            <div className="col-2 pt-2" key={idx}>
                                <div className="card">
                                    <div className="card-body bg-light">
                                        <h5 className="card-title">{item.name}</h5>
                                        <p className="card-text">{item.hp}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

            </div>

            <div className="row pt-5 pb-99">
                <div className="col-12">
                    <button
                        className="col-12 btn btn-secondary"
                        disabled={props.isSubmitting}
                        onClick={props.saveClickHandler}
                    >
                        저장
                    </button>
                </div>
            </div>
        </>
    );
}

export default ConfigTab4