import { useEffect } from "react";
import { debugMode } from "../../js/server";

const NaverLogin = ({ setGetToken, setUserInfo }) => {
    const { naver } = window;
    const NAVER_CALLBACK_URL = (debugMode === true)
        ? process.env.REACT_APP_NAVER_DEV_REDIRECT_URI
        : process.env.REACT_APP_NAVER_REDIRECT_URI;
    const NAVER_CLIENT_ID = (debugMode === true)
        ? process.env.REACT_APP_NAVER_DEV_CLIENT_ID
        : process.env.REACT_APP_NAVER_CLIENT_ID;

    const initializeNaverLogin = () => {
        const naverLogin = new naver.LoginWithNaverId({
            clientId: NAVER_CLIENT_ID,
            callbackUrl: NAVER_CALLBACK_URL,
            isPopup: false,
            loginButton: { color: "green", type: 3, height: 50 },
            callbackHandle: true,
        });

        naverLogin.init();
        naverLogin.getLoginStatus(async function (status) {
            if (status) {
                // const userid = naverLogin.user.getEmail();
            }
        });
    };

    useEffect(() => {
        initializeNaverLogin();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div id="naverIdLogin"></div>
        </>
    );
};

export default NaverLogin;
