import { useState, useCallback } from 'react';

const useLocation = () => {
    const [location, setLocation] = useState({
        lat: null,
        lng: null,
        startCenter: { lat: null, lng: null },
        center: { lat: null, lng: null },
        currentLocation: { lat: null, lng: null },
        byRefetch: { lat: null, lng: null }
    });

    const updateLocation = useCallback((newLocation) => {
        setLocation(prevLocation => ({
            ...prevLocation,
            ...newLocation
        }));
    }, []);

    return [location, updateLocation];
};

export default useLocation;