import axios from "axios";

const debugMode = false;
const url = debugMode
    ? process.env.REACT_APP_DEV_DOMAIN
    : process.env.REACT_APP_REAL_DOMAIN;
const urlName = debugMode
    ? process.env.REACT_APP_DEV_FULL_URI
    : process.env.REACT_APP_REAL_FULL_URI;
/* const protocol = debugMode
    ? process.env.REACT_APP_DEV_PROTOCOL
    : process.env.REACT_APP_REAL_PROTOCOL; */

export const apiUrl = `https://${url}.intravan.co.kr/Controllers/JMTService.aspx`;
export const apiFormDataUrl = `https://${url}.intravan.co.kr/Controllers/uploadImageJMT.aspx`;
export const apiPromotionFormDataUrl = `https://${url}.intravan.co.kr/Controllers/JmtBoardSave.aspx`;
export const imageUrl = `https://${url}.intravan.co.kr/`;

const axiosConfig = {
    headers: {
        "Content-Type": "application/x-www-form-urlencoded",
    },
};

async function reqData(jsonData) {
    const res = await axios.post(apiUrl, JSON.stringify(jsonData), axiosConfig);
    return res;
}

async function reqFormData(formData) {
    const res = await axios({
        method: "post",
        headers: {
            "Content-Type": "multipart/form-data",
        },
        url: apiFormDataUrl,
        data: formData,
    });

    return res;
}

async function reqPromotionFormData(formData) {
    const res = await axios({
        method: "post",
        headers: {
            "Content-Type": "multipart/form-data",
        },
        url: apiPromotionFormDataUrl,
        data: formData,
    });

    return res;
}

async function getClientIp() {
    const res = await axios.get(
        "https://www.cloudflare.com/cdn-cgi/trace",
        axiosConfig
    );

    return res;
}

async function reqGeoData(addr1) {
    const url =
        "https://dapi.kakao.com/v2/local/search/address.json?query=" + addr1;
    const axiosGeoConfig = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            common: { Authorization: "KakaoAK 90ad17cc583a13e32a503e9351185e5c" },
        },
    };

    const res = await axios.post(url, null, axiosGeoConfig);

    return res;
}

export {
    reqData,
    reqFormData,
    reqGeoData,
    reqPromotionFormData,
    getClientIp,
    axiosConfig,
    debugMode,
    urlName,
};
