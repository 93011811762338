// useComInfo.js
import { useCallback, useState } from "react";
import { comInfoInitialState } from "../initialState/comInfoInitialState";
import isEqual from 'lodash/isEqual';

const useComInfo = () => {
    const [comInfo, setComInfo] = useState(comInfoInitialState); // 초기 데이터는 null로 설정
    const [originalData, setOriginalData] = useState(comInfoInitialState); // 초기 데이터 저장

    // comInfo 업데이트 함수
    const updateComInfo = useCallback((newComInfo) => {
        setComInfo((prevComInfo) => ({
            ...prevComInfo,
            ...newComInfo,
        }));
    }, []);

    // 초기 데이터 설정 함수
    const setInitialData = useCallback((data) => {
        setOriginalData(data);
        updateComInfo(data);
    }, [updateComInfo]);

    // 데이터 변경 여부 확인
    const isModified = () => {
        return !isEqual(originalData, comInfo);
    }

    // 초기값으로 재설정
    const resetToInitialData = useCallback(() => {
        setComInfo(originalData);
    }, [originalData]);

    return [comInfo, updateComInfo, isModified, setInitialData, resetToInitialData];
};

export default useComInfo;