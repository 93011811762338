import { useEffect } from "react";
import { LOGIN_MESSAGE } from "../../initialState/comInfoInitialState";
import KakaoLogin from "./KakaoLogin";
import NaverLogin from "./NaverLogin";

function LoginComp(props) {
    useEffect(() => {
        if (props.userInfo.loginAccount && props.userInfo.loginKind) {
            alert(LOGIN_MESSAGE);
            window.document.location = "/";
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="login-wrapper">
            <div id="login-header">
                <img src="../../images/ico_close02_xhdpi.png" alt=""
                    onClick={() => props.setIsPopLogin(false)} />
            </div>
            <div id="login-content">
                <div>
                    <p className="title-font blue-text">내 차가 좋아하는</p>
                    <p className="title-font blue-text">정비맛집</p>
                </div>
            </div>
            <div id="login-footer">
                <NaverLogin />
                <KakaoLogin />
            </div>
        </div>
    );
}

export default LoginComp;
