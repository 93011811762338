import { useEffect } from "react";
import { useLocation } from "react-router";
import { removeCookie, setCookie } from "../../js/cookie";
import { debugMode, reqData } from "../../js/server";
import { getToday } from "../../js/util";

function NaverCallback(props) {
    const location = useLocation();
    const { naver } = window;
    const NAVER_CALLBACK_URL = (debugMode === true)
        ? process.env.REACT_APP_NAVER_DEV_REDIRECT_URI
        : process.env.REACT_APP_NAVER_REDIRECT_URI;
    const NAVER_CLIENT_ID = (debugMode === true)
        ? process.env.REACT_APP_NAVER_DEV_CLIENT_ID
        : process.env.REACT_APP_NAVER_CLIENT_ID;

    const naverLogin = new naver.LoginWithNaverId({
        clientId: NAVER_CLIENT_ID,
        callbackUrl: NAVER_CALLBACK_URL,
        isPopup: false,
        callbackHandle: true,
    });

    const getNaverToken = () => {
        const token = location.hash.split("=")[1].split("&")[0];
        if (token) {
            localStorage.setItem("jmtLoginKind", "NAVER");
            localStorage.setItem("jmtToken", token);
            localStorage.setItem("jmtLoginDate", getToday());

            naverLogin.init();
            naverLogin.getLoginStatus(function (status) {
                if (status) {
                    const email = naverLogin.user.getEmail();

                    if (email) {
                        localStorage.setItem("jmtLoginAccount", email);

                        const userInfo = {
                            loginKind: "NAVER",
                            token: localStorage.getItem("jmtToken"),
                            loginDate: localStorage.getItem("jmtLoginDate"),
                            loginAccount: localStorage.getItem("jmtLoginAccount"),
                        };

                        setCookie("jmtUserInfo", JSON.stringify(userInfo), {
                            path: "/",
                        });

                        const jsonData = {
                            servicename: "UserRegist",
                            platform: "NAVER",
                            email: localStorage.getItem("jmtLoginAccount"),
                        };

                        reqData(jsonData)
                            .then((res) => {
                                //업체 안내를 통해 접속한 경우 로그인 후에도 업체로 돌아간다.
                                const callBackComcode = localStorage.getItem("callBackComcode");
                                if (callBackComcode)
                                    window.location.href = "/" + callBackComcode;
                                else window.location.href = "/";
                            })
                            .catch((err) => {
                                console.error(err);
                            });
                    } else {
                        alert(
                            "이메일주소는 필수항목입니다.\n계속 실패시 관리자에게 문의하세요~"
                        );
                    }
                }
            });
        }
    };

    useEffect(
        () => {
            localStorage.removeItem("jmtLoginKind");
            localStorage.removeItem("jmtLoginAccount");
            localStorage.removeItem("jmtLoginDate");
            localStorage.removeItem("jmtToken");
            removeCookie("userinfo");

            if (!location.hash) {
                alert("로그인에 실패하였습니다. 계속 문제 발생시 문의하세요.");
                window.location.href = "/";
                return;
            }
            getNaverToken();
        },
        // eslint-disable-next-line
        []
    );

    return null;
}

export default NaverCallback;
