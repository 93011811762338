import React, { useEffect, useState } from 'react'
import ResponsiveGallery from "react-responsive-gallery";
import { useDispatch } from "react-redux";
import "./PromotionInfo.css";

function PromotionInfo({ selectedPromotion, setIsPromotion, selectedPromotionComName }) {
    const dispatch = useDispatch();
    const [photos, setPhotos] = useState([]);

    useEffect(() => {
        dispatch({ type: "SET_BOTTOM_SHEET_STATE", payload: { isOpen: true, currentState: 'promotion' } });
        window.history.pushState({ sheetState: 'promotion' }, '', `#${'promotion'}`);

        const photosArray = selectedPromotion?.prom_photo.map(photo => ({ src: photo.file_url }));
        setPhotos(photosArray);
        // eslint-disable-next-line
    }, [])

    return (
        <div className="login-wrapper">
            <div id="login-header">
                <img src="../../images/ico_close02_xhdpi.png" alt=""
                    onClick={() => setIsPromotion(false)} />
            </div>
            <div id="login-content">
                <div className="pb-5">
                    <p className="title-font blue-text">{selectedPromotionComName ?? ""}에서 진행하는</p>
                    <p className="title-font blue-text">프로모션</p>
                </div>
                <ul className='prom-container col-11 mx-auto'>
                    <li className="font-weight-bold">
                        <span className="badge badge-primary mr-2 px-2 py-1">제목</span>
                        {selectedPromotion.prom_title ?? ""}
                    </li>

                    <li className="pt-1">
                        <span className="badge badge-primary mr-2 px-2 py-1">기간</span>
                        {selectedPromotion.prom_sday ?? ""}~{selectedPromotion.prom_eday ?? ""}

                        <hr />
                    </li>
                    <li className="pt-2">
                        {selectedPromotion.prom_memo.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                    </li>
                    <li className="pt-3">
                        <ResponsiveGallery
                            media={photos}
                            useLightBox={true}
                            numOfMediaPerRow={{
                                xs: 2,
                                s: 3,
                                m: 3,
                                l: 3,
                                xl: 3,
                                xxl: 3
                            }}
                        />
                    </li>
                </ul>
            </div>
            <div id="login-footer">
            </div>
        </div>
    );
}

export default PromotionInfo