import ContentLoader from "react-content-loader";

function ContentLoaderReserv(props) {
  return (
    <ContentLoader
      speed={2}
      width={430}
      height={90}
      viewBox="0 0 430 90"
      backgroundColor="#e5e5e5"
      foregroundColor="#f1f1f1"
      {...props}
    >
      <circle cx="42" cy="42" r="42" />
      <rect x="142" y="7" rx="0" ry="0" width="176" height="20" />
      <rect x="142" y="35" rx="0" ry="0" width="211" height="20" />
      <rect x="142" y="63" rx="7" ry="7" width="279" height="20" />
      <rect x="367" y="7" rx="7" ry="7" width="43" height="20" />
    </ContentLoader>
  );
}

export default ContentLoaderReserv;
