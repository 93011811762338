import { useRef, forwardRef, useImperativeHandle, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BottomSheet } from "react-spring-bottom-sheet";
import ComDescr from "../ComDescr";
import ComDetailInfo from "../ComDetailInfo";
import ComList from "../ComList";
import ReservationList from "../ReservationList";
import SimpleComList from "../SimpleComList";

const CustomBottomSheet = forwardRef((props, ref) => {
    const sheetRef = useRef();
    const dispatch = useDispatch();
    const bottomSheet = useSelector((state) => state.bottomSheet);

    function snapToBottom() {
        if (sheetRef.current) sheetRef.current.snapTo(0);
    }

    function snapExpand() {
        if (sheetRef.current) sheetRef.current.snapTo(1000);
    }

    useImperativeHandle(ref, () => ({
        snapToBottom,
        snapExpand,
    }));

    useEffect(() => {
        const onPopState = (event) => {
            //bottomSheet.currentState 전 페이지, event.state.sheetState 현재 페이지
            console.log(bottomSheet.currentState);
            console.log(event.state.sheetState);
            // SITE 아웃
            if (!bottomSheet.currentState) {
                window.history.back();
            }

            if (bottomSheet.currentState === "promotion") {
                props.setIsPromotion(false);
            }

            // 상세페이지에서 초기로
            if (
                event.state.sheetState === "" &&
                (
                    bottomSheet.currentState === "detail-info" ||
                    bottomSheet.currentState === "list" ||
                    bottomSheet.currentState === "reservation"
                )
            ) {
                props.unSelectCompany();
            }

            if (event.state.sheetState === "descr" && bottomSheet.currentState === "detail-info") {
                props.unSelectCompany();
            }

            if (event.state.sheetState === "list" && bottomSheet.currentState === "detail-info") {
                props.unSelectCompany();
            }

            dispatch({ type: "SET_BOTTOM_SHEET_STATE", payload: event.state.sheetState });
        };

        window.addEventListener('popstate', onPopState);
        return () => window.removeEventListener('popstate', onPopState);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bottomSheet, dispatch]);

    return (
        <>
            <BottomSheet
                ref={sheetRef}
                open={props.open}
                snapPoints={({ maxHeight }) => [0.4 * maxHeight, 0.83 * maxHeight]}
                defaultSnap={({ lastSnap, snapPoints }) => {
                    lastSnap ?? Math.max(...snapPoints);
                    // 확장 상태인지 아닌지 구분
                    props.setIsExpanded(snapPoints[0] * 1.2 > lastSnap ? true : false);
                }}
                blocking={false}
                expandOnContentDrag={true} >
                {props.reservationHist ? (
                    <ReservationList
                        userInfo={props.userInfo}
                        isLoading={props.isLoading}
                        setIsLoading={props.setIsLoading} />
                ) : props.selectedCompanyByList ? (
                    <ComDetailInfo
                        setIsPromotion={props.setIsPromotion}
                        isPromotion={props.isPromotion}
                        setSelectedPromotion={props.setSelectedPromotion}
                        setSelectedPromotionComName={props.setSelectedPromotionComName}
                        unSelectCompany={props.unSelectCompany}
                        selectCompany={props.selectCompany}
                        selectedCompanyByList={props.selectedCompanyByList}
                        photos={props.photos}
                        isLoading={props.isLoading}
                        location={props.location}
                        myComItem={props.myComItem}
                        fetchMyComItem={props.fetchMyComItem}
                        myComItemHandler={props.myComItemHandler}
                        userInfo={props.userInfo}
                        showTooltipHandler={props.showTooltipHandler} />
                ) : props.selectedCompanyByMap === null ? (
                    props.isExpanded === true ? (
                        <div className="main-cominfo-wrapper">
                            <SimpleComList
                                detailCompanyView={props.detailCompanyView}
                                selectCompany={props.selectCompany}
                                listItem={props.listItem}
                                isLoading={props.isLoading}
                                myComItem={props.myComItem}
                                userInfo={props.userInfo}
                                getComPhoto={props.getComPhoto} />
                        </div>
                    ) : (
                        <div className="main-cominfo-wrapper">
                            <ComList
                                detailCompanyView={props.detailCompanyView}
                                listItem={props.listItem}
                                isLoading={props.isLoading}
                                myComItem={props.myComItem}
                                userInfo={props.userInfo}
                                getComPhoto={props.getComPhoto} />
                        </div>
                    )
                ) : (
                    <div className="main-cominfo-wrapper">
                        <ComDescr
                            setIsPromotion={props.setIsPromotion}
                            isPromotion={props.isPromotion}
                            setSelectedPromotion={props.setSelectedPromotion}
                            setSelectedPromotionComName={props.setSelectedPromotionComName}
                            setSelectedCompanyByMap={props.setSelectedCompanyByMap}
                            selectedCompanyByMap={props.selectedCompanyByMap}
                            detailCompanyView={props.detailCompanyView}
                            selectedComcode={props.selectedComcode}
                            unSelectCompany={props.unSelectCompany}
                            listItem={props.listItem}
                            isLoading={props.isLoading}
                            myComItem={props.myComItem}
                            userInfo={props.userInfo}
                            getComPhoto={props.getComPhoto}
                            showTooltipHandler={props.showTooltipHandler} />
                    </div>
                )}
            </BottomSheet>
        </>
    );
});

export default CustomBottomSheet;
