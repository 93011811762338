import { Route, Switch, useLocation } from "react-router-dom";
import { useEffect } from "react";

import ScrollToTop from "./comp/comm/ScrollToTop";
import Main from "./comp/Main";
import Config from "./comp/Config";
import Page404 from "./comp/comm/Page404";
import Loading from "./comp/comm/Loading";
import NaverCallback from "./comp/comm/NaverCallback";
import { useSelector } from "react-redux";
import KakaoCallback from "./comp/comm/KakaoCallback";

function App(props) {
  let location = useLocation();
  const store = useSelector((state) => state);

  useEffect(() => {
    // if (currentPath === location.pathname) window.location.reload();
    // currentPath = location.pathname;
  }, [location]);

  return (
    <div className="App">
      <Loading loading={store.rdcLoading} />

      <ScrollToTop>
        <Switch>
          <Route exact path="/" component={Main} />
          <Route exact path="/:comcode" component={Main} />

          <Route exact path="/Callback/Naver" component={NaverCallback} />
          <Route exact path="/Callback/Kakao" component={KakaoCallback} />

          <Route exact path="/Config/:comcode" component={Config} />

          {/* 없는 페이지 */}
          <Route path="/:id" component={Page404} />
        </Switch>
      </ScrollToTop>
    </div>
  );
}

export default App;
