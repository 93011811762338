import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

// 로딩Bar 상태 StateReducer 시작
const initLoading = true;

const rdcLoading = (state = initLoading, action) => {
    switch (action.type) {
        case "loadingChange":
            state = action.payload;
            return state;
        default:
            return state;
    }
};
// 로딩Bar 상태 StateReducer 끝

// SideMenu 상태 StateReducer 시작
const initIsMenu = false;

const rdcIsMenu = (state = initIsMenu, action) => {
    switch (action.type) {
        case "isMenuChange":
            state = action.payload;
            return state;
        default:
            return state;
    }
};
// 로딩Bar 상태 StateReducer 끝

// CustomBottomSheet 관련 리듀서
const bottomSheetReducer = (state = { isOpen: false, currentState: '' }, action) => {
    switch (action.type) {
        case 'SET_BOTTOM_SHEET_STATE':
            state = action.payload;
            return state;
        default:
            return state;
    }
};
// customBottomSheet 상태 StateReducer 끝

const rootReducer = combineReducers({
    rdcLoading,
    rdcIsMenu,
    bottomSheet: bottomSheetReducer,
});

// reducer 여러개 사용시 combineReducers로 합쳐서 obj형태로 저장함
let store = configureStore({ reducer: rootReducer });

export { store };
