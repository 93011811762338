export const comInfoInitialState = {
    comcode: null,
    comname: null,
    service_div: null,
    intro: null,
    address: null,
    addr1: null,
    addr2: null,
    gis_x_coor: null,
    gis_y_coor: null,
    tel0_0: null,
    tel1_0: null,
    tel0_1: null,
    tel1_1: null,
    tel0_2: null,
    tel1_2: null,
    further_map: null,
    luse: null,
    distance: null,
    mon_stime: null,
    mon_etime: null,
    tue_stime: null,
    tue_etime: null,
    wed_stime: null,
    wed_etime: null,
    thur_stime: null,
    thur_etime: null,
    fri_stime: null,
    fri_etime: null,
    sat_stime: null,
    sat_etime: null,
    sun_stime: null,
    sun_etime: null,
    break_stime: null,
    break_etime: null,
    set_reserv: null,
    set_day_reserv: null,
    jarr_homepage: [],
    jarr_comphoto: [],
    jarr_prg_userinfo: [],
    jarr_reserv_dayset: [],
    jarr_reserv_offset: [],
    jarr_setcode: [],
    config_com: null,
    set_reserv_hp0_1: null,
    set_reserv_hp1_1: null,
    set_reserv_hp0_2: null,
    set_reserv_hp1_2: null,
    set_reserv_hp0_3: null,
    set_reserv_hp1_3: null,
    set_reserv_day_usecnt: null,
    set_reserv_day_time: null,
    closed_today: null,
};

export const NOT_LOGIN_MESSAGE = "해당 기능을 사용하려면 로그인이 필요합니다.\n서비스를 이용하시려면 페이지 우측 상단의 로그인 버튼을 클릭하여 인증해 주십시오.";
export const LOGIN_MESSAGE = "현재 로그인 상태입니다.\n서비스를 계속 이용하실 수 있습니다.";
export const SERVER_ERROR_MESSAGE = "서버 처리 중 문제가 발생했습니다.\n문제가 지속되면, 고객 지원팀 또는 관리자에게 문의하시기 바랍니다.";
export const brands = ['현대', '기아', '르노삼성', '쌍용', '쉐보레'];
export const fortes = ['수입차', '전기차', '세차', '타이어'];
export const distances = ['10', '20', '30'];
export const workTime = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'];