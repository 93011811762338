import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getPhoneNumber, getWorkState } from "../js/util";

function ComList(props) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: "SET_BOTTOM_SHEET_STATE", payload: { isOpen: true, currentState: 'list' } });
        window.history.pushState({ sheetState: 'list' }, '', `#${'list'}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <ul className="cominfo-wrapper">
            {props.listItem.length > 0 ? (
                props.listItem.map((item, idx) => {
                    return (
                        <li key={idx}>
                            <div className="cominfo-box-detail">
                                <ul>
                                    <li>
                                        <table className="cominfo-list">
                                            <tbody>
                                                <tr>
                                                    <td
                                                        colSpan={2}
                                                        onClick={() =>
                                                            props.detailCompanyView(item.comcode)
                                                        }
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        {props.myComItem ? (
                                                            props.myComItem
                                                                .map((_item) => {
                                                                    return _item["comcode"];
                                                                })
                                                                .indexOf(item.comcode) > -1 ? (
                                                                <>
                                                                    {item.comname}{" "}
                                                                    <img
                                                                        src="../../images/icon_pin_xhdpi.png"
                                                                        alt=""
                                                                        className="icon-18"
                                                                    />
                                                                </>
                                                            ) : (
                                                                item.comname
                                                            )
                                                        ) : (
                                                            item.comname
                                                        )}
                                                        <br />
                                                        <span>{item.service_div || ""}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <img
                                                            src="../images/ico_map_xhdpi.png"
                                                            alt="ico_map"
                                                            className="cominfo-icon"
                                                        />
                                                    </td>
                                                    <td>{item.address || ""}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <img
                                                            src="../images/ico_clock_xhdpi.png"
                                                            alt="ico_clock"
                                                            className="cominfo-icon"
                                                        />
                                                    </td>
                                                    <td
                                                        dangerouslySetInnerHTML={{
                                                            __html: getWorkState(item) || "",
                                                        }}
                                                    ></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <img
                                                            src="../images/ico_call02_xhdpi.png"
                                                            alt="call"
                                                            className="cominfo-icon"
                                                        />
                                                    </td>
                                                    <td
                                                        dangerouslySetInnerHTML={{
                                                            __html: getPhoneNumber(item) || "",
                                                        }}
                                                    ></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </li>
                                    <li>
                                        <div
                                            className="detail-list-photo"
                                            onClick={() => props.detailCompanyView(item.comcode)}
                                            style={{
                                                backgroundImage: "url(" + props.getComPhoto(item) + ")",
                                                cursor: "pointer",
                                            }}
                                        >
                                            &nbsp;
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    );
                })
            ) : (
                <div className="cominfo-empty-box">
                    <i className="fas fa-search-location" />
                    <p>주변에 등록된 정비업체를 찾을 수 없습니다.</p>
                </div>
            )}
        </ul>
    );
}

export default ComList;
