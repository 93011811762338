import { useEffect } from "react";
import { useDispatch } from "react-redux";
import ContentLoaderCominfo from "./comm/ContentLoaderCominfo";

function SimpleComList(props) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: "SET_BOTTOM_SHEET_STATE", payload: { isOpen: true, currentState: '' } });
        window.history.pushState({ sheetState: '' }, '', '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <ul>
            <li>
                <p className="title-font b-700 blue-text tsize-large">
                    #우리동네 정비맛집
                </p>
                <p>위치 기반 검증된 자동차 정비 업체를 소개해 드립니다.</p>
            </li>
            {props.isLoading ? (
                <ContentLoaderCominfo props={props} />
            ) : (
                <li>
                    {props.listItem.length > 0 ? (
                        props.listItem.map((item, idx) => {
                            if (idx > 1) {
                                return false;
                            } else {
                                return (
                                    <div className="cominfo-box" key={idx}>
                                        <ul>
                                            <li
                                                onClick={() => props.detailCompanyView(item.comcode)}
                                                style={{
                                                    backgroundImage: "url(" + props.getComPhoto(item) + ")",
                                                    cursor: "pointer",
                                                }}
                                            ></li>
                                            <li
                                                onClick={() => props.detailCompanyView(item.comcode)}
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                            >
                                                {item.comname}
                                            </li>
                                            <li>
                                                {item.tel0_0 && item.tel1_0 ? (
                                                    <a href={`tel:${item.tel0_0}${item.tel1_0}`}>
                                                        <img
                                                            src="../images/ico_call02_xhdpi.png"
                                                            alt="call"
                                                            className="cominfo-icon"
                                                        />
                                                        &nbsp; 전화&nbsp;&nbsp;&nbsp;
                                                    </a>
                                                ) : null}
                                                <span
                                                    onClick={(e) => props.selectCompany(e, item.comcode)}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <img
                                                        src="../images/ico_place_xhdpi.png"
                                                        alt="place"
                                                        className="cominfo-icon"
                                                    />
                                                    &nbsp;위치
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                );
                            }
                        })
                    ) : (
                        <div className="cominfo-empty-box">
                            <i className="fas fa-search-location" />
                            <p>주변에 등록된 정비업체를 찾을 수 없습니다.</p>
                        </div>
                    )}
                </li>
            )}
        </ul>
    );
}

export default SimpleComList;
