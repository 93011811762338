import { useEffect, useRef, useState } from "react";
import { NaverMap, Marker } from "react-naver-maps";
import { brands, distances, fortes } from "../../initialState/comInfoInitialState";
import { fnKakaoNaviClick, fnNaverNaviClick } from "../../js/util";
import "./Map.css";

function Map(props) {
    const [isDetailSearchOpen, setIsDetailSearchOpen] = useState(false);
    const navermaps = window.naver.maps;
    const inputRef = useRef();

    useEffect(() => {
        if (!props.selectedComcode) return;
        console.log(props.selectedComcode);
    }, [props.selectedComcode]);

    return (
        <div className="main-map-wrapper">
            <div className="custom-search-mob">
                <ul>
                    <li>
                        <input
                            ref={inputRef}
                            type="text"
                            className="search-input-mob"
                            placeholder="지역 또는 상호명 입력"
                            value={props.keyword || ""}
                            onChange={(e) => props.setKeyword(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    props.setIsSearchModeHandler(true, props.keyword);
                                    e.target.blur();
                                }
                            }}
                            onFocus={() => props.keywordInputFocusHandler(false)}
                            onBlur={() => props.keywordInputFocusHandler(true)}
                        />
                        <button
                            className="search-input-button-mob"
                            onClick={() => {
                                props.setIsSearchModeHandler(true, props.keyword);
                            }}
                        >
                            <img src="../images/ico_search02_xhdpi.png" alt="search" />
                        </button>
                    </li>
                    <div className={`pt-2 custom-search-options ${isDetailSearchOpen ? 'expanded' : 'collapsed'}`}>
                        {isDetailSearchOpen && (
                            <>
                                <li>
                                    {brands.map((brand, idx) => (
                                        <button
                                            key={idx}
                                            className={`custom-badge ${props.selectedHashTag.includes(`brand:${brand}`) && 'on'}`}
                                            onClick={() => props.setSelectedHashTagHandler(brand, 'brand')}
                                            disabled={props.isLoading}
                                        >
                                            {brand}
                                        </button>
                                    ))}
                                </li>
                                <li>
                                    {fortes.map((forte, idx) => (
                                        <button
                                            key={idx}
                                            className={`custom-badge ${props.selectedHashTag.includes(`forte:${forte}`) && 'on'}`}
                                            onClick={() => props.setSelectedHashTagHandler(forte, 'forte')}
                                            disabled={props.isLoading}
                                        >
                                            {forte}
                                        </button>
                                    ))}
                                </li>
                                <li>
                                    {distances.map((distance, idx) => (
                                        <button
                                            key={idx}
                                            className={`custom-badge ${props.distance === distance && 'on'}`}
                                            onClick={() => props.setSelectedHashTagHandler(distance, 'distance')}
                                            disabled={props.isLoading}>
                                            {distance}km
                                        </button>
                                    ))}
                                </li>
                            </>
                        )}
                    </div>
                    <li className={`pt-2 ${props.selectedComcode ? 'd-flex justify-content-between' : 'text-right'}`}>
                        {props.selectedComcode ?
                            <div>
                                <img
                                    className="icon-42"
                                    src="../images/icon/icon-kakao-navi.png"
                                    alt=""
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                        fnKakaoNaviClick(
                                            props.selectedCompanyByMap,
                                            props.location.currentLocation
                                        )
                                    }
                                />
                                <img
                                    className="icon-42"
                                    src="../images/icon/icon-naver-map.png"
                                    alt=""
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                        fnNaverNaviClick(
                                            props.selectedCompanyByMap,
                                            props.location.currentLocation
                                        )
                                    }
                                />
                            </div>
                            : null
                        }
                        <button className="detailed-search" onClick={() => setIsDetailSearchOpen(prev => !prev)}>
                            {isDetailSearchOpen ? "닫기" : <><i className="fa-regular fa-arrow-down-short-wide pr-2"></i>상세검색</>}
                        </button>
                    </li>
                </ul>
            </div>

            <img
                src="../images/ico_place_dot_xhdpi.png"
                alt="처음위치로"
                style={{ cursor: "pointer" }}
                className="btn-current-location"
                onClick={() => props.posInitHandler()}
            />

            <NaverMap
                naverRef={(ref) => props.setNaverRef(ref)}
                id="jmt-maps"
                className="main-maps"
                center={props.location.center}
                onCenterChanged={(center) => {
                    props.updateLocation({ center: { lat: center._lat, lng: center._lng } });
                }}
                onDragstart={() => {
                    const lat = props.naverRef.map.center._lat;
                    const lng = props.naverRef.map.center._lng;

                    props.updateLocation({ startCenter: { lat, lng } });
                }}
                onDragend={() => {
                    const lat = props.naverRef.map.center._lat;
                    const lng = props.naverRef.map.center._lng;
                    const zoomLevel = props.naverRef.map.zoom;

                    props.updateLocation({ lat, lng });
                    props.setMapDragEndHandler(lat, lng, zoomLevel);
                }}
                defaultZoom={16}
                zoomControl={true}
                zoomControlOptions={{
                    position: navermaps.Position.TOP_RIGHT,
                }}
            >
                <Marker
                    position={
                        new navermaps.LatLng(
                            props.location.currentLocation.lat,
                            props.location.currentLocation.lng
                        )
                    }
                    icon={{
                        content: `<img src="../images/ico_myplace.png" width="16" />`,
                        size: new navermaps.Size(16, 16),
                        anchor: new navermaps.Point(6, 14), // 지도상 위치에서 이미지 위치의 offset값
                    }}
                    title="현재위치"
                />

                {props.listItem.length > 0
                    ? props.listItem.map((item, idx) => {
                        const gis_x_coor = parseFloat(item.gis_x_coor);
                        const gis_y_coor = parseFloat(item.gis_y_coor);
                        const iconUrl =
                            item.comcode === props.selectedComcode
                                ? "../images/ico_result_o_xhdpi.png"
                                : "../images/ico_result_xhdpi.png";
                        const zIndex = item.comcode === props.selectedComcode ? 100 : 50;
                        return (
                            <Marker
                                key={idx}
                                position={new navermaps.LatLng(gis_x_coor, gis_y_coor)}
                                onClick={(e) => {
                                    inputRef.current.blur();
                                    props.selectCompany(e, item.comcode);
                                }}
                                icon={{
                                    content: `<img src="${iconUrl}" width="48" />`,
                                    size: new navermaps.Size(48, 48),
                                    anchor: new navermaps.Point(20, 46), // 지도상 위치에서 이미지 위치의 offset값
                                }}
                                title={item.comname}
                                zIndex={zIndex}
                            />
                        );
                    })
                    : null}
            </NaverMap>
        </div>
    );
}

export default Map;
