import { useDispatch, useSelector } from "react-redux";
import { removeCookie } from "../../js/cookie";
import "./SideMenu.css";

function SideMenu(props) {
    const dispatch = useDispatch();
    const store = useSelector((state) => state);
    const fadeStyle = store.rdcIsMenu ? "fadeIn" : "fadeOut";
    const LOCAL_STORAGE_KEYS = {
        loginAccount: "jmtLoginAccount",
        token: "jmtToken",
        loginDate: "jmtLoginDate",
        callBackComcode: "callBackComcode",
    };

    const handleLogout = () => {
        Object.values(LOCAL_STORAGE_KEYS).forEach((key) => localStorage.removeItem(key));
        removeCookie("jmtUserInfo");

        const callBackComcode = localStorage.getItem(LOCAL_STORAGE_KEYS.callBackComcode);
        window.location.href = callBackComcode ? `/${callBackComcode}` : "/";
    }

    return (
        <div
            className={`menu-wrapper ${fadeStyle}`}
            onClick={(e) => {
                dispatch({ type: "isMenuChange", payload: false });
            }}
        >
            <div
                className={`menu-container ${fadeStyle}`}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <ul>
                    <li>
                        <div>
                            <img
                                src="../../images/ico_user_xhdpi.png"
                                alt=""
                                className="icon"
                            />
                            &nbsp;&nbsp;
                            {props.userInfo.loginAccount ? (
                                <span style={{ fontWeight: "bold" }}>
                                    {props.userInfo.loginAccount.split("@")[0]}
                                </span>
                            ) : (
                                ""
                            )}
                        </div>
                        <div>
                            <img
                                src="../images/ico_close02_xhdpi.png"
                                alt="close"
                                style={{ width: "24px", cursor: "pointer" }}
                                onClick={(e) => {
                                    dispatch({ type: "isMenuChange", payload: false });
                                }}
                            />
                        </div>
                    </li>
                    <li
                        className="pt-4"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            if (props.myComItem.length > 0) {
                                props.selectedMyComItem();

                                dispatch({ type: "isMenuChange", payload: false });
                            } else {
                                alert(
                                    "등록된 내 정비맛집이 아직 없네요~\n업체 상세정보 화면에서 등록이 가능합니다."
                                );
                            }
                        }}>
                        내 정비맛집
                    </li>
                    <li
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            props.selectedMyReservationHist();
                            dispatch({ type: "isMenuChange", payload: false });
                        }}>
                        내 예약일지
                    </li>
                    <li className="pt-5 text-right">
                        <button
                            className="btn btn-sm btn-outline-dark logout-button"
                            onClick={handleLogout}>
                            <span className="logout-text">로그아웃</span>
                            <i className="fa-solid fa-arrow-right-from-bracket pl-2" />
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default SideMenu;
