import React from 'react'
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from 'date-fns/locale';
import { formatDate } from '../../js/util';
import { SERVER_ERROR_MESSAGE } from '../../initialState/comInfoInitialState';
import { reqPromotionFormData } from '../../js/server';

function PromotionModal(props) {
    const closeClick = () => {
        initPromotionData();
        props.onHide();
    }

    const initPromotionData = () => {
        props.setPromotionData({
            serial: "",
            subject: "",
            contents: "",
            eventStartDate: new Date(),
            eventEndDate: new Date(),
            selectedFiles: [],
            previews: []
        });
    }

    const saveClick = async () => {
        if (props.promotionData.subject?.length < 1) {
            alert("제목을 짧게 입력해 주세요.")
        } else if (!props.promotionData.eventStartDate) {
            alert("게시기간이 필요합니다.")
        } else if (!props.promotionData.eventEndDate) {
            alert("게시기간이 필요합니다.")
        } else if (props.promotionData.contents?.length < 1) {
            alert("프로모션 내용을 간단하게 입력해 주세요.")
        } else {
            if (window.confirm("저장하시겠습니까?")) {
                const formData = new FormData();
                formData.append("servicename", "PromotionSave");
                formData.append("comcode", props.comInfo.comcode);
                formData.append("prom_idx", props.promotionData.serial ?? "");
                formData.append("prom_title", props.promotionData.subject);
                formData.append("prom_sday", formatDate(props.promotionData.eventStartDate));
                formData.append("prom_eday", formatDate(props.promotionData.eventEndDate));
                formData.append("prom_memo", props.promotionData.contents);
                props.promotionData.selectedFiles.forEach((file, index) => {
                    formData.append(`file${index}`, file, file.name);
                });

                try {
                    const res = await reqPromotionFormData(formData);
                    if (res.data.result === "OK") {
                        initPromotionData();
                        props.fetchData();
                        props.onHide();
                    } else {
                        alert("오류가 발생했습니다: " + res.data.errMsg);
                    }
                } catch (err) {
                    console.error("오류 발생: ", err);
                    alert(SERVER_ERROR_MESSAGE);
                }
            }
        }
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="modal-title h4" id="contained-modal-title-vcenter">프로모션</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-12 pb-3">
                    <label htmlFor="subject" className="config-title">제목을 짧게 입력해 주세요.</label>
                    <input
                        type="text"
                        className="form-control"
                        id="subject"
                        aria-describedby="subjectHelp"
                        maxLength={50}
                        value={props.promotionData?.subject || ""}
                        onChange={(e) => {
                            props.setPromotionData((prev) => ({
                                ...prev,
                                subject: e.target.value
                            }));
                        }}
                    />
                    <small id="subjectHelp" className="form-text blue-text">* 필수</small>
                </div>

                <div className="col-12 pb-3">
                    <label htmlFor="eventStartDate" className="config-title">게시기간을 선택해 주세요.</label>
                    <br />
                    <ul className="d-flex">
                        <li>
                            <div className="react-datepicker-wrapper">
                                <div className="react-datepicker__input-container">
                                    <DatePicker
                                        name="eventStartDate"
                                        id="eventStartDate"
                                        locale={ko}
                                        dateFormat="yyyy-MM-dd"
                                        className="form-control col"
                                        minDate={new Date()}
                                        closeOnScroll={true}
                                        placeholderText="시작일"
                                        autoComplete="off"
                                        selected={props.promotionData?.eventStartDate || null}
                                        onChange={(date) => {
                                            props.setPromotionData((prev) => ({
                                                ...prev,
                                                eventStartDate: formatDate(date)
                                            }));
                                        }}
                                    />
                                </div>
                            </div>
                        </li>
                        <li className="pl-2 pr-2">
                            <p>~</p>
                        </li>
                        <li>
                            <div className="react-datepicker-wrapper">
                                <div className="react-datepicker__input-container">
                                    <DatePicker
                                        name="eventEndDate"
                                        id="eventEndDate"
                                        locale={ko}
                                        dateFormat="yyyy-MM-dd"
                                        className="form-control col"
                                        minDate={new Date()}
                                        closeOnScroll={true}
                                        placeholderText="종료일"
                                        autoComplete="off"
                                        selected={props.promotionData?.eventEndDate || null}
                                        onChange={(date) => {
                                            props.setPromotionData((prev) => ({
                                                ...prev,
                                                eventEndDate: formatDate(date)
                                            }));
                                        }}
                                    />
                                </div>
                            </div>
                        </li>
                    </ul>
                    <small id="eventDateHelp" className="form-text blue-text">* 필수</small>
                </div>

                <div className="col-12 pb-3">
                    <label htmlFor="contents" className="config-title">프로모션 내용을 간단하게 입력해 주세요.</label>
                    <textarea
                        className="form-control"
                        id="contents"
                        rows="7"
                        value={props.promotionData?.contents || ""}
                        onChange={(e) => {
                            props.setPromotionData((prev) => ({
                                ...prev,
                                contents: e.target.value
                            }));
                        }}
                    ></textarea>
                    <small id="contentsHelp" className="form-text blue-text">* 필수</small>
                </div>

                <div className="col-12">
                    <label htmlFor="contents" className="config-title">쿠폰 및 프로모션 관련 이미지가 있다면 선택해 주세요.</label>
                    <br />
                    <input
                        type="file"
                        id="promotionImgFile"
                        className="hidden"
                        onChange={props.handlePromotionImageChange}
                        multiple={true}
                        accept="image/gif, image/jpeg, image/png"
                    />
                    <label htmlFor="promotionImgFile">
                        <div className="file-input-custom">
                            <i className="far fa-image" aria-hidden="true"></i>
                            <p>사진 추가</p>
                        </div>
                    </label>
                </div>

                <div className="col-12">
                    <ul className="preview-wrapper">
                        {props.promotionData?.uploadedFiles?.map((img, idx) => {
                            return (
                                <li key={idx}>
                                    <button
                                        className="preview-delete-icon btn btn-primary"
                                        onClick={() => props.deleteRegistedFiles(img)}
                                    >
                                        등록완료&nbsp;
                                        <i className="far fa-trash-alt" />
                                    </button>
                                    <img src={img.file_url} alt="" />
                                </li>
                            );
                        })}
                        {props.promotionData.previews.map((img, idx) => {
                            return (
                                <li key={idx}>
                                    <button
                                        className="preview-delete-icon btn btn-secondary"
                                        onClick={() => props.deletePromotionSelectedFiles(idx)}
                                    >
                                        등록대기중&nbsp;
                                        <i className="far fa-trash-alt" />
                                    </button>
                                    <img src={img.preview} alt="" />
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-primary" onClick={saveClick}>{props.promotionData.serial ? "수정" : "저장"}</button>
                {
                    props.promotionData.serial && <button type="button" className="btn btn-secondary btn btn-danger" onClick={props.deleteClick}>삭제</button>
                }
                <button type="button" className="btn btn-secondary btn btn-primary" onClick={closeClick}>취소</button>
            </Modal.Footer>
        </Modal>
    )
}

export default PromotionModal