import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { NOT_LOGIN_MESSAGE } from "../initialState/comInfoInitialState";
import { debugMode } from "../js/server";
import {
    getHomepageCheck,
    getPhoneNumber,
    getWorkState,
} from "../js/util";

function ComDescr(props) {
    const dispatch = useDispatch();

    const fnReservClick = () => {
        const comname = props.selectedCompanyByMap.comname;
        const comcode = props.selectedCompanyByMap.comcode;
        const prgcode = props.selectedCompanyByMap.prgcode;
        const platform = props.userInfo.loginKind;
        const email = props.userInfo.loginAccount;

        if (email && platform) {
            if (
                window.confirm(
                    `[${comname}]에 정비 예약 해보시겠어요?\n예약 후 취소는 업체로 연락주셔야 됩니다~`
                )
            ) {
                if (debugMode) {
                    window.location.href = `http://adbcp.goldauto.co.kr/Self/reservation.aspx?comcode=${comcode}&prgcode=${prgcode}&platform=${platform}&email=${email}`;
                } else {
                    window.location.href = `https://adbcp.intravan.co.kr/Self/reservation.aspx?comcode=${comcode}&prgcode=${prgcode}&platform=${platform}&email=${email}`;
                }
            }
        } else {
            window.alert(NOT_LOGIN_MESSAGE);
            props.showTooltipHandler();
        }
    };

    useEffect(() => {
        dispatch({ type: "SET_BOTTOM_SHEET_STATE", payload: { isOpen: true, currentState: 'descr' } });
        window.history.pushState({ sheetState: 'descr' }, '', `#${'descr'}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="cominfo-box-detail">
            <ul>
                <li>
                    <table className="cominfo-list">
                        <tbody>
                            <tr>
                                <td colSpan={2}>
                                    <ul className="cominfo-list-comname">
                                        <li
                                            onClick={() =>
                                                props.detailCompanyView(
                                                    props.selectedCompanyByMap.comcode
                                                )
                                            }
                                            style={{
                                                cursor: "pointer",
                                            }}
                                        >
                                            {props.myComItem ? (
                                                props.myComItem
                                                    .map((item) => {
                                                        return item["comcode"];
                                                    })
                                                    .indexOf(props.selectedCompanyByMap.comcode) > -1 ? (
                                                    <>
                                                        {props.selectedCompanyByMap.comname}{" "}
                                                        <img
                                                            src="../../images/icon_pin_xhdpi.png"
                                                            alt=""
                                                            className="icon-18"
                                                        />
                                                    </>
                                                ) : (
                                                    props.selectedCompanyByMap.comname
                                                )
                                            ) : (
                                                props.selectedCompanyByMap.comname
                                            )}
                                            <br />
                                            <span>
                                                {props.selectedCompanyByMap.service_div || ""}
                                            </span>
                                        </li>
                                        <li>
                                            {props.selectedCompanyByMap.set_reserv === "1" ? (
                                                <span
                                                    className="custom-btn blue"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={fnReservClick}
                                                >
                                                    정비예약
                                                </span>
                                            ) : null}
                                        </li>
                                    </ul>
                                </td>
                            </tr>

                            {
                                (props.selectedCompanyByMap.jarr_promotion?.length > 0) &&
                                <tr onClick={() => {
                                    props.setSelectedPromotion(props.selectedCompanyByMap.jarr_promotion[0]);
                                    props.setSelectedPromotionComName(props.selectedCompanyByMap.comname);
                                    props.setIsPromotion(true);
                                }} style={{ cursor: 'pointer' }}>
                                    <td>
                                        <i className="fa-duotone fa-calendar-star text-danger font-weight-bold" />
                                    </td>
                                    <td>
                                        <span className="prom-text font-weight-bold">{
                                            props.selectedCompanyByMap.jarr_promotion[0]?.prom_title?.length > 20
                                                ? props.selectedCompanyByMap.jarr_promotion[0]?.prom_title.slice(0, 18) + "..."
                                                : props.selectedCompanyByMap.jarr_promotion[0]?.prom_title
                                        }</span>
                                    </td>
                                </tr>
                            }

                            <tr>
                                <td>
                                    <img
                                        src="../images/ico_map_xhdpi.png"
                                        alt="ico_map"
                                        className="cominfo-icon"
                                    />
                                </td>
                                <td>{props.selectedCompanyByMap.address || ""}</td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src="../images/ico_clock_xhdpi.png"
                                        alt="ico_clock"
                                        className="cominfo-icon"
                                    />
                                </td>
                                <td
                                    dangerouslySetInnerHTML={{
                                        __html: getWorkState(props.selectedCompanyByMap) || "",
                                    }}
                                ></td>
                            </tr>
                            <tr>
                                <td>
                                    <img
                                        src="../images/ico_call02_xhdpi.png"
                                        alt="call"
                                        className="cominfo-icon"
                                    />
                                </td>
                                <td
                                    dangerouslySetInnerHTML={{
                                        __html: getPhoneNumber(props.selectedCompanyByMap) || "",
                                    }}
                                ></td>
                            </tr>
                        </tbody>
                    </table>
                </li>
                <li>
                    <div
                        className="detail-list-photo"
                        onClick={() =>
                            props.detailCompanyView(props.selectedCompanyByMap.comcode)
                        }
                        style={{
                            backgroundImage:
                                "url(" + props.getComPhoto(props.selectedCompanyByMap) + ")",
                            cursor: "pointer",
                        }}
                    >
                        &nbsp;
                    </div>
                </li>
            </ul>

            <div className="btn-sns-wrapper">
                {props.selectedCompanyByMap.tel0_0 &&
                    props.selectedCompanyByMap.tel1_0 ? (
                    <a
                        href={`tel:${props.selectedCompanyByMap.tel0_0}${props.selectedCompanyByMap.tel1_0}`}
                    >
                        <span className="custom-btn blue">전화</span>
                    </a>
                ) : null}

                {getHomepageCheck(props.selectedCompanyByMap.jarr_homepage)
                    ? props.selectedCompanyByMap.jarr_homepage.map((item, idx) => {
                        return item.target_url ? (
                            <a href={item.target_url} target="new" key={idx}>
                                <span className="custom-btn purple">
                                    {item.codename || ""}
                                </span>
                            </a>
                        ) : null;
                    })
                    : null}
            </div>

            <div className="btn-back-wrapper">
                <p
                    className="btn-more"
                    onClick={() => props.setSelectedCompanyByMap(null)}
                    style={{ margin: "0 auto" }}
                >
                    뒤로가기
                    <img
                        src="../images/ico_arrow01.png"
                        alt=""
                        className="cominfo-icon"
                    />
                </p>
            </div>
        </div>
    );
}

export default ComDescr;
