import { brands, fortes } from "../../initialState/comInfoInitialState";

function SearchContainer(props) {
    return (
        <div className="pc-container">
            <section>
                <header>
                    {/* <img
            src="../images/iv_logo01_xhdpi.png"
            alt="logo"
            className="logo"
          /> */}
                </header>
                <div className="search-container-content">
                    <div className="search-contents">
                        <ul>
                            <li className="title-font b-700 blue-text tsize-xlarge">
                                자동차 정비에도
                                <br />
                                맛이 있다?
                            </li>
                            <li>
                                나의 소중한 자동차!
                                <br />
                                아무데나 맡길 수 있나요?
                            </li>
                            <li>
                                나만의 <span className="blue-text">정비맛집</span> 지금 찾아
                                보세요~
                            </li>
                            <li>
                                <div className="custom-search">
                                    <input
                                        type="text"
                                        className="search-input-pc"
                                        placeholder="지역 또는 상호명 입력"
                                        value={props.keyword || ""}
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 13) {
                                                props.setIsSearchModeHandler(true, props.keyword);
                                                e.target.blur();
                                            }
                                        }}
                                        onChange={(e) => props.setKeyword(e.target.value)}
                                    />
                                    <button
                                        className="search-input-button-pc"
                                        onClick={() => {
                                            props.setIsSearchModeHandler(true, props.keyword);
                                        }}
                                    >
                                        <img src="../images/ico_search01_xhdpi.png" alt="search" />
                                    </button>
                                </div>
                            </li>
                            <li>
                                <ul>
                                    <li>
                                        {brands.map((brand) => (
                                            <button
                                                key={brand}
                                                className={`custom-badge ${props.selectedHashTag.includes(`brand:${brand}`) && 'on'}`}
                                                onClick={() => props.setSelectedHashTagHandler(brand, 'brand')}
                                            >
                                                {brand}
                                            </button>
                                        ))}

                                    </li>
                                    <li>
                                        {fortes.map((forte) => (
                                            <button
                                                key={forte}
                                                className={`custom-badge ${props.selectedHashTag.includes(`forte:${forte}`) && 'on'}`}
                                                onClick={() => props.setSelectedHashTagHandler(forte, 'forte')}
                                            >
                                                {forte}
                                            </button>
                                        ))}
                                    </li>
                                    <li>
                                        <button className={`custom-badge ${props.distance === '10' && 'on'}`} onClick={() => props.setSelectedHashTagHandler('10', 'distance')}>
                                            10km
                                        </button>
                                        <button className={`custom-badge ${props.distance === '20' && 'on'}`} onClick={() => props.setSelectedHashTagHandler('20', 'distance')}>
                                            20km
                                        </button>
                                        <button className={`custom-badge ${props.distance === '30' && 'on'}`} onClick={() => props.setSelectedHashTagHandler('30', 'distance')}>
                                            30km
                                        </button>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <footer>
                    <ul className="footer-container">
                        <li>
                            <a href="https://bit.ly/3RyFHHG" target="_blank" rel="noreferrer">
                                <img
                                    src="../images/ico_blog01_xhdpi.png"
                                    alt="blog"
                                    className="sns-icon"
                                />
                            </a>
                            <a href="https://bit.ly/3EevYTX" target="_blank" rel="noreferrer">
                                <img
                                    src="../images/ico_cafe01_xhdpi.png"
                                    alt="cafe"
                                    className="sns-icon"
                                />
                            </a>
                            <a href="https://bit.ly/3M2mvRq" target="_blank" rel="noreferrer">
                                <img
                                    src="../images/ico_youtube01_xhdpi.png"
                                    alt="youtube"
                                    className="sns-icon"
                                />
                            </a>
                        </li>
                        <li>
                            <img
                                src="../images/ico_chat01_xhdpi.png"
                                alt="chat"
                                className="chat-icon"
                            />
                            &nbsp; 제휴문의 : admin@intravan.com
                        </li>
                        <li>
                            <img
                                src="../images/ico_chat01_xhdpi.png"
                                alt="chat"
                                className="chat-icon"
                            />
                            &nbsp; 기술문의 : rla100@intravan.com
                        </li>
                        <li>
                            [우 12925] 경기도 하남시 미사대로 520
                            <br />
                            현대지식산업센터 한강미사 2차 C동 713호
                        </li>
                        <li>사업자등록번호 : 215-81-90952 대표전화 : 1522-3840</li>
                    </ul>
                </footer>
            </section>
        </div>
    );
}

export default SearchContainer;
