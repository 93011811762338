function ConfigTab2(props) {
    const rearrangeBrands = (brands) => {
        const ssangyongIndex = brands.findIndex(brand => brand.codename === "쌍용");
        const chevroletIndex = brands.findIndex(brand => brand.codename === "쉐보레");

        if (ssangyongIndex >= 0 && chevroletIndex >= 0) {
            const chevroletBrand = brands.splice(chevroletIndex, 1)[0];
            brands.splice(ssangyongIndex + 1, 0, chevroletBrand);
        }

        return brands;
    };

    const rearrangedBrands = rearrangeBrands(props.codeBrand);

    return (
        <>
            <div className="row pt-4">
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="intro">
                            <span className="config-title">
                                정비 전문 브랜드가 따로 있으신가요?
                            </span>
                            <br />* 미선택시 전문 브랜드는 따로 노출되지 않습니다.
                        </label>
                    </div>
                </div>
                <div className="col-2">
                    {props.comInfo.jarr_setcode.filter((item) => item.kind === "BRAND")
                        .length > 0 ? (
                        <div className="btn-code" onClick={props.brandSwitchClick}>
                            따로 없음
                        </div>
                    ) : (
                        <div className="btn-code on">따로 없음</div>
                    )}
                </div>
                <div className="col-10">
                    <div className="row">
                        {rearrangedBrands?.map((item, idx) => {
                            let styleClass = "";
                            const itemToFind = props.comInfo?.jarr_setcode?.find((setcode) => {
                                return setcode.kind === "BRAND" && setcode.code === item.code;
                            });
                            const i = props.comInfo.jarr_setcode.indexOf(itemToFind);
                            if (i > -1) {
                                styleClass = "on";
                            }

                            return (
                                <div className="col-2 pb-3" key={idx}>
                                    <div
                                        className={`btn-code ${styleClass}`}
                                        onClick={() => props.setCodeHandler(item, "BRAND")}
                                    >
                                        <ul>
                                            <li>
                                                <img
                                                    src={`../../images/icon/${item.etc}`}
                                                    alt={item.codename}
                                                    className="config-brand-icon"
                                                />
                                            </li>
                                            <li>{item.codename}</li>
                                        </ul>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

            <div className="row pt-5">
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="intro">
                            <span className="config-title">
                                업체를 대표할 작업중 전문분야가 따로 있으신가요?
                            </span>
                            <br />* 미선택시 전문분야는 따로 노출되지 않습니다.
                        </label>
                    </div>
                </div>

                <div className="col-12">
                    {props.codeForte.map((item, idx) => {
                        const itemToFind = props.comInfo?.jarr_setcode?.find((setcode) => {
                            return setcode.kind === "FORTE" && setcode.code === item.code;
                        });
                        const i = props.comInfo?.jarr_setcode?.indexOf(itemToFind);

                        return (
                            <div className="form-check form-check-inline pr-4" key={idx}>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={`forte_${item.code}`}
                                    value={item.code}
                                    onChange={(e) => props.forteCodeHandler(e, item)}
                                    checked={i > -1 ? true : false}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor={`forte_${item.code}`}
                                >
                                    {item.codename}
                                </label>
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className="row pt-5">
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="homepage" className="config-title">
                            따로 운영하시는 홈페이지나 SNS가 있으신가요?
                        </label>
                        <ul className="sns-input-wrapper">
                            {props.comInfo?.jarr_homepage?.map((item, idx) => {
                                return (
                                    <li key={idx}>
                                        <div className="input-group">
                                            <div className="input-group-append">
                                                <select id="comUrl" style={{ width: "150px" }}>
                                                    <option value={item.code || ""}>{item.codename || ""}</option>
                                                </select>
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="homepage"
                                                maxLength={200}
                                                placeholder="https://"
                                                onChange={(e) => props.setHomePageHandler(e, idx)}
                                                value={item.target_url || ""}
                                            />
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>

            <div className="row pt-5 pb-99">
                <div className="col-12">
                    <button
                        className="col-12 btn btn-secondary"
                        disabled={props.isSubmitting}
                        onClick={props.saveClickHandler}
                    >
                        저장
                    </button>
                </div>
            </div>
        </>
    );
}

export default ConfigTab2;
