import ContentLoader from "react-content-loader";

function ContentLoaderCominfo(props) {
    return (
        <ContentLoader
            speed={2}
            viewBox="0 0 430 240"
            backgroundColor="#e5e5e5"
            foregroundColor="#f1f1f1"
            {...props}
        >
            <rect x="220" y="18" rx="10" ry="10" width="205" height="140" />
            <rect x="220" y="170" rx="5" ry="5" width="205" height="23" />
            <rect x="220" y="198" rx="5" ry="5" width="150" height="20" />
            <rect x="5" y="18" rx="10" ry="10" width="205" height="140" />
            <rect x="5" y="170" rx="5" ry="5" width="205" height="23" />
            <rect x="5" y="198" rx="5" ry="5" width="150" height="20" />
        </ContentLoader>
    );
}

export default ContentLoaderCominfo;
