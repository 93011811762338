import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setCookie } from "../../js/cookie";
import { debugMode } from "../../js/server";

function Header(props) {
    const dispatch = useDispatch();
    const interval = useRef(null);
    let scrollPosition = 0;
    const [didScroll, setDidScroll] = useState(null);
    const [lastScrollTop, setlastscrollTop] = useState(0);
    const [navClass, setNavClass] = useState("nav-down");
    const delta = 5;
    const updateScroll = () => {
        scrollPosition = window.scrollY || document.documentElement.scrollTop;
    };

    function tick() {
        if (didScroll) {
            hasScrolled();
            setDidScroll(false);
        }
    }

    function hasScrolled() {
        if (Math.abs(lastScrollTop - scrollPosition) <= delta) {
            return;
        }

        if (scrollPosition > lastScrollTop && scrollPosition > 104) {
            setNavClass("nav-up");
        } else {
            if (scrollPosition + window.innerHeight < document.body.scrollHeight) {
                setNavClass("nav-down");
            }
        }

        setlastscrollTop(scrollPosition);
    }

    useEffect(() => {
        // 로그인하지 않은 상태에서만 풍선 도움말을 보여줍니다.
        props.showTooltipHandler();
    }, [props.userInfo.loginAccount, props.userInfo.loginKind]);

    useEffect(() => {
        /* if (debugMode === true) {
            const userInfo = {
                loginKind: "NAVER",
                token: "1234",
                loginDate: "2022-11-21",
                loginAccount: "kbk0437@naver.com",
            };

            setCookie("jmtUserInfo", JSON.stringify(userInfo), {
                maxAge: 60 * 60 * 24 * 7,
                path: "/",
            });
        } */

        window.addEventListener("scroll", updateScroll);
        window.addEventListener("scroll", () => {
            setDidScroll(true);
        });

        interval.current = setInterval(tick, 250);
        return () => {
            clearInterval(interval.current);
        };
    });

    return (
        <div className={`${navClass} mob-header`}>
            <ul>
                <li className="sub-title-font b-500 tsize-medium" style={{ cursor: 'pointer' }} onClick={props.posInitHandler}>
                    내 차가 좋아하는{" "}
                    <span className="title-font b-700 blue-text tsize-large">
                        정비맛집
                    </span>
                </li>
                <li>
                    {props.userInfo.loginAccount && props.userInfo.loginKind ? (
                        <img
                            src="../images/ico_menu_xhdpi.png"
                            alt=""
                            style={{ cursor: "pointer", width: "24px" }}
                            onClick={(e) => {
                                dispatch({ type: "isMenuChange", payload: true });
                            }}
                        />
                    ) : (
                        <div className="tooltip-container">
                            <img
                                src="../../images/ico_userinfo_xhdpi.png"
                                alt="로그인"
                                className="icon"
                                onClick={() =>
                                    props.setIsPopLogin((prevValue) => (prevValue ? false : true))
                                }
                                style={{ cursor: "pointer" }}
                            />
                            {props.showTooltip && <div className="tooltip tooltip-show">로그인하고 예약해 보세요!</div>}
                        </div>
                    )}
                </li>
            </ul>
        </div>
    );
}

export default Header;
