function ConfigTab3(props) {
    return (
        <>
            <div className="row pt-4">
                <div className="col-12">
                    <label htmlFor="intro">
                        <span className="config-title">정기 휴무일을 선택해 주세요.</span>
                        <br />* 미선택시 "매주 일요일"로 노출됩니다.
                    </label>
                </div>
                <div className="col-12">
                    <ul>
                        {props.codeClose?.map((item, idx) => {
                            let styleClass = "";
                            const itemToFind = props.comInfo?.jarr_setcode?.find((setcode) => {
                                return (
                                    setcode.kind === "STOP_CLOSE" && setcode.code === item.code
                                );
                            });
                            const i = props.comInfo?.jarr_setcode?.indexOf(itemToFind);
                            if (i > -1) {
                                styleClass = "on";
                            }

                            return (
                                <li
                                    className={`btn-holiday ${styleClass}`}
                                    key={idx}
                                    onClick={() => props.setCodeHandler(item, "STOP_CLOSE")}
                                >
                                    {item.codename}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>

            <div className="row pt-5">
                <div className="col-12">
                    <label htmlFor="intro">
                        <span className="config-title">공휴일에 따로 근무하시나요?</span>
                        <br />* 미선택시 "공휴일 휴무"로 노출됩니다.
                    </label>
                </div>

                <div className="col-12">
                    <ul>
                        {props.codeHoliday?.map((item, idx) => {
                            let styleClass = "";
                            const itemToFind = props.comInfo?.jarr_setcode?.find((setcode) => {
                                return (
                                    setcode.kind === "HOW_HOLIDAY" && setcode.code === item.code
                                );
                            });
                            const i = props.comInfo?.jarr_setcode?.indexOf(itemToFind);
                            if (i > -1) {
                                styleClass = "on";
                            }

                            return (
                                <li
                                    className={`btn-holiday ${styleClass}`}
                                    key={idx}
                                    onClick={() => props.setCodeHandler(item, "HOW_HOLIDAY")}
                                >
                                    {item.codename}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>

            <div className="row pt-5">
                <div className="col-12">
                    <label htmlFor="intro" className="config-title">
                        영업시간을 선택해 주세요.
                    </label>
                </div>
            </div>

            <div className="row">
                <div className="col-1">
                    <div className="working-time-wrapper bg-light">월</div>
                </div>
                {props.comInfo?.jarr_setcode?.findIndex((item, idx) => {
                    return item.kind === "STOP_CLOSE" && item.codename === "월";
                }) < 0 ? (
                    <>
                        <div className="col-5">
                            <select
                                className="form-control work-select-input"
                                id="mon_stime"
                                value={props.comInfo.mon_stime || ""}
                                onChange={props.setCominfoHandler}
                            >
                                {props.officeHour.map((item, idx) => {
                                    return (
                                        <option value={item} key={idx}>
                                            {item}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="col-1 text-center">~</div>
                        <div className="col-5">
                            <select
                                className="form-control work-select-input"
                                id="mon_etime"
                                value={props.comInfo.mon_etime || ""}
                                onChange={props.setCominfoHandler}
                            >
                                {props.officeHour.map((item, idx) => {
                                    return (
                                        <option value={item} key={idx}>
                                            {item}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </>
                ) : (
                    <div className="col-11">
                        <div className="working-time-wrapper">정기휴일</div>
                    </div>
                )}
            </div>

            <div className="row pt-1">
                <div className="col-1">
                    <div className="working-time-wrapper bg-light">화</div>
                </div>
                {props.comInfo?.jarr_setcode?.findIndex((item, idx) => {
                    return item.kind === "STOP_CLOSE" && item.codename === "화";
                }) < 0 ? (
                    <>
                        <div className="col-5">
                            <select
                                className="form-control work-select-input"
                                id="tue_stime"
                                value={props.comInfo.tue_stime || ""}
                                onChange={props.setCominfoHandler}
                            >
                                {props.officeHour.map((item, idx) => {
                                    return (
                                        <option value={item} key={idx}>
                                            {item}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="col-1 text-center">~</div>
                        <div className="col-5">
                            <select
                                className="form-control work-select-input"
                                id="tue_etime"
                                value={props.comInfo.tue_etime || ""}
                                onChange={props.setCominfoHandler}
                            >
                                {props.officeHour.map((item, idx) => {
                                    return (
                                        <option value={item} key={idx}>
                                            {item}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </>
                ) : (
                    <div className="col-11">
                        <div className="working-time-wrapper">정기휴일</div>
                    </div>
                )}
            </div>

            <div className="row pt-1">
                <div className="col-1">
                    <div className=" working-time-wrapper bg-light">수</div>
                </div>
                {props.comInfo?.jarr_setcode?.findIndex((item, idx) => {
                    return item.kind === "STOP_CLOSE" && item.codename === "수";
                }) < 0 ? (
                    <>
                        <div className="col-5">
                            <select
                                className="form-control work-select-input"
                                id="wed_stime"
                                value={props.comInfo.wed_stime || ""}
                                onChange={props.setCominfoHandler}
                            >
                                {props.officeHour.map((item, idx) => {
                                    return (
                                        <option value={item} key={idx}>
                                            {item}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="col-1 text-center">~</div>
                        <div className="col-5">
                            <select
                                className="form-control work-select-input"
                                id="wed_etime"
                                value={props.comInfo.wed_etime || ""}
                                onChange={props.setCominfoHandler}
                            >
                                {props.officeHour.map((item, idx) => {
                                    return (
                                        <option value={item} key={idx}>
                                            {item}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </>
                ) : (
                    <div className="col-11">
                        <div className="working-time-wrapper">정기휴일</div>
                    </div>
                )}
            </div>

            <div className="row pt-1">
                <div className="col-1">
                    <div className="working-time-wrapper bg-light">목</div>
                </div>
                {props.comInfo?.jarr_setcode?.findIndex((item, idx) => {
                    return item.kind === "STOP_CLOSE" && item.codename === "목";
                }) < 0 ? (
                    <>
                        <div className="col-5">
                            <select
                                className="form-control work-select-input"
                                id="thur_stime"
                                value={props.comInfo.thur_stime || ""}
                                onChange={props.setCominfoHandler}
                            >
                                {props.officeHour.map((item, idx) => {
                                    return (
                                        <option value={item} key={idx}>
                                            {item}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="col-1 text-center">~</div>
                        <div className="col-5">
                            <select
                                className="form-control work-select-input"
                                id="thur_etime"
                                value={props.comInfo.thur_etime || ""}
                                onChange={props.setCominfoHandler}
                            >
                                {props.officeHour.map((item, idx) => {
                                    return (
                                        <option value={item} key={idx}>
                                            {item}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </>
                ) : (
                    <div className="col-11">
                        <div className="working-time-wrapper">정기휴일</div>
                    </div>
                )}
            </div>

            <div className="row pt-1">
                <div className="col-1">
                    <div className="working-time-wrapper bg-light">금</div>
                </div>
                {props.comInfo?.jarr_setcode?.findIndex((item, idx) => {
                    return item.kind === "STOP_CLOSE" && item.codename === "금";
                }) < 0 ? (
                    <>
                        <div className="col-5">
                            <select
                                className="form-control work-select-input"
                                id="fri_stime"
                                value={props.comInfo.fri_stime || ""}
                                onChange={props.setCominfoHandler}
                            >
                                {props.officeHour.map((item, idx) => {
                                    return (
                                        <option value={item} key={idx}>
                                            {item}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="col-1 text-center">~</div>
                        <div className="col-5">
                            <select
                                className="form-control work-select-input"
                                id="fri_etime"
                                value={props.comInfo.fri_etime || ""}
                                onChange={props.setCominfoHandler}
                            >
                                {props.officeHour.map((item, idx) => {
                                    return (
                                        <option value={item} key={idx}>
                                            {item}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </>
                ) : (
                    <div className="col-11">
                        <div className="working-time-wrapper">정기휴일</div>
                    </div>
                )}
            </div>

            <div className="row pt-1">
                <div className="col-1">
                    <div className="working-time-wrapper bg-light">토</div>
                </div>
                {props.comInfo?.jarr_setcode?.findIndex((item, idx) => {
                    return item.kind === "STOP_CLOSE" && item.codename === "토";
                }) < 0 ? (
                    <>
                        <div className="col-5">
                            <select
                                className="form-control work-select-input"
                                id="sat_stime"
                                value={props.comInfo.sat_stime || ""}
                                onChange={props.setCominfoHandler}
                            >
                                {props.officeHour.map((item, idx) => {
                                    return (
                                        <option value={item} key={idx}>
                                            {item}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="col-1 text-center">~</div>
                        <div className="col-5">
                            <select
                                className="form-control work-select-input"
                                id="sat_etime"
                                value={props.comInfo.sat_etime || ""}
                                onChange={props.setCominfoHandler}
                            >
                                {props.officeHour.map((item, idx) => {
                                    return (
                                        <option value={item} key={idx}>
                                            {item}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </>
                ) : (
                    <div className="col-11">
                        <div className="working-time-wrapper">정기휴일</div>
                    </div>
                )}
            </div>

            <div className="row pt-1">
                <div className="col-1">
                    <div className="working-time-wrapper bg-light">일</div>
                </div>
                {props.comInfo?.jarr_setcode?.findIndex((item, idx) => {
                    return item.kind === "STOP_CLOSE" && item.codename === "일";
                }) < 0 ? (
                    <>
                        <div className="col-5">
                            <select
                                className="form-control work-select-input"
                                id="sun_stime"
                                value={props.comInfo.sun_stime || ""}
                                onChange={props.setCominfoHandler}
                            >
                                {props.officeHour.map((item, idx) => {
                                    return (
                                        <option value={item} key={idx}>
                                            {item}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="col-1 text-center">~</div>
                        <div className="col-5">
                            <select
                                className="form-control work-select-input"
                                id="sun_etime"
                                value={props.comInfo.sun_etime || ""}
                                onChange={props.setCominfoHandler}
                            >
                                {props.officeHour.map((item, idx) => {
                                    return (
                                        <option value={item} key={idx}>
                                            {item}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </>
                ) : (
                    <div className="col-11">
                        <div className="working-time-wrapper">정기휴일</div>
                    </div>
                )}
            </div>

            <div className="row pt-5">
                <div className="col-12">
                    <label htmlFor="intro">
                        <span className="config-title">휴게시간을 선택해 주세요.</span>
                        <br />* 설정시 휴게시간이 노출되며 예약기능을 사용하는 경우 고객이 예약을 하지 못하도록 설정됩니다.
                    </label>
                </div>
            </div>

            <div className="row">
                <div className="col-1">
                    <div
                        className="working-time-wrapper bg-light"
                        style={{ fontSize: ".8rem", lineHeight: "110%" }}
                    >
                        Break
                        <br />
                        Time
                    </div>
                </div>
                <div className="col-5">
                    <select
                        className="form-control work-select-input"
                        id="break_stime"
                        value={props.comInfo.break_stime || ""}
                        onChange={props.setCominfoHandler}
                    >
                        <option value="">없음</option>
                        <option value="11:00">11:00</option>
                        <option value="11:30">11:30</option>
                        <option value="12:00">12:00</option>
                        <option value="12:30">12:30</option>
                        <option value="13:00">13:00</option>
                        <option value="13:30">13:30</option>
                        <option value="14:00">14:00</option>
                        <option value="14:30">14:30</option>
                    </select>
                </div>
                <div className="col-1 text-center">~</div>
                <div className="col-5">
                    <select
                        className="form-control work-select-input"
                        id="break_etime"
                        value={props.comInfo.break_etime || ""}
                        onChange={props.setCominfoHandler}
                    >
                        <option value="00:00">없음</option>
                        <option value="11:00">11:00</option>
                        <option value="11:30">11:30</option>
                        <option value="12:00">12:00</option>
                        <option value="12:30">12:30</option>
                        <option value="13:00">13:00</option>
                        <option value="13:30">13:30</option>
                        <option value="14:00">14:00</option>
                        <option value="14:30">14:30</option>
                    </select>
                </div>
            </div>

            <div className="row pt-5 pb-99">
                <div className="col-12">
                    <button
                        className="col-12 btn btn-secondary"
                        disabled={props.isSubmitting}
                        onClick={props.saveClickHandler}
                    >
                        저장
                    </button>
                </div>
            </div>
        </>
    );
}

export default ConfigTab3;
