import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { reqData } from "../js/server";
import ContentLoaderReserv from "./comm/ContentLoaderReserv";

function ReservationList(props) {
    const [reservList, setReservList] = useState([]);
    const dispatch = useDispatch();

    const fetchReservList = () => {
        const platform = props.userInfo.loginKind;
        const email = props.userInfo.loginAccount;
        if (platform && email) {
            const jsonData = {
                servicename: "UserReserv",
                platform,
                email,
            };

            props.setIsLoading(true);
            reqData(jsonData)
                .then((res) => {
                    if (res.data.result === "OK") {
                        if (res.data.jsonData.length > 0) {
                            setReservList(res.data.jsonData);
                        }
                    } else {
                        alert("고유코드 오류! 관리자에게 문의하세요!");
                    }
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    setTimeout(() => {
                        props.setIsLoading(false);
                        //   dispatch({ type: "loadingChange", payload: false });
                    }, 300);
                });
        } else {
            alert(
                "로그인 후 이용 가능한 메뉴입니다.\n\n우측상단 사용자 아이콘을 이용해 주세요."
            );
            setReservList([]);
        }
    };

    const getReservDay = (day, time) => {
        const arrWeek = ["일", "월", "화", "수", "목", "금", "토"];
        const vDay = new Date(day);
        const week = `(${arrWeek[vDay.getDay()]})`;
        return `${day}${week} ${time.substr(0, 2)}:${time.substr(2, 2)}`;
    };

    const GetRecStateClassName = (value) => {
        let stateContainer = "";
        switch (value) {
            case "정비완료":
                stateContainer = "badge badge-primary px-3 py-1";
                break;
            case "예약취소":
                stateContainer = "badge badge-danger px-3 py-1";
                break;
            default:
                stateContainer = "badge badge-secondary px-3 py-1";
                break;
        }

        return stateContainer;
    }

    const downloadReport = async (item) => {
        try {
            console.log(item);
            const response = await fetch(item.report_url);
            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = `${item.carno}_${item.reserv_day}`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(downloadUrl);
            document.body.removeChild(a);
        } catch (error) {
            console.error('Download failed:', error);
            alert('파일 다운로드에 실패했습니다.');
        }
    };

    useEffect(() => {
        dispatch({ type: "SET_BOTTOM_SHEET_STATE", payload: { isOpen: true, currentState: 'reservation' } });
        window.history.pushState({ sheetState: 'reservation' }, '', `#${'reservation'}`);

        fetchReservList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container">
            <div className="row pt-4">
                <div className="col-12">
                    <ul className="cominfo-wrapper text-left">
                        {props.isLoading ? (
                            <>
                                <li className="pt-3 pb-3">
                                    <ContentLoaderReserv props={props} />
                                </li>
                                <li className="pt-3 pb-3">
                                    <ContentLoaderReserv props={props} />
                                </li>
                                <li className="pt-3 pb-3">
                                    <ContentLoaderReserv props={props} />
                                </li>
                            </>
                        ) : reservList.length > 0 ? (
                            <>
                                {reservList.map((item, idx) => {
                                    return (
                                        <li className="pt-3 pb-3" key={idx}>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-3">
                                                        <img
                                                            src={item.file_url}
                                                            alt=""
                                                            className="rounded-circle profile-image"
                                                        />
                                                    </div>
                                                    <div className="col-9">
                                                        <div className="container">
                                                            <div className="row">
                                                                <div className="col-8 blue-text b-700">
                                                                    {item.comname || ""}
                                                                </div>
                                                                <div className="col-4">
                                                                    <span className={GetRecStateClassName(item.recstatename)}>{item.recstatename}</span>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    {getReservDay(
                                                                        item.reserv_day,
                                                                        item.reserv_time
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="row pt-1">
                                                                {
                                                                    item.report_url
                                                                        ? <>
                                                                            <div className="col-6">
                                                                                <button
                                                                                    className="btn-more active w-100"
                                                                                    onClick={() => window.location.href = `/${item.comcode}`}
                                                                                >
                                                                                    <i className="fa-sharp fa-solid fa-location-dot pr-2" />
                                                                                    업체 위치 보기
                                                                                </button>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <button
                                                                                    className="btn-more w-100"
                                                                                    onClick={() => downloadReport(item)}
                                                                                >
                                                                                    <i className="fa-solid fa-file-pdf pr-2" />정비명세서
                                                                                </button>
                                                                            </div>
                                                                        </>
                                                                        : <div className="col-12">
                                                                            <button
                                                                                className="btn-more active w-100"
                                                                                onClick={() => (window.location.href = `/${item.comcode}`)}>
                                                                                <i className="fa-sharp fa-solid fa-location-dot pr-2" />
                                                                                업체 위치 보기
                                                                            </button>
                                                                        </div>
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })}
                            </>
                        ) : (
                            <li className="pt-3 pb-3">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <i
                                                className="far fa-smile-wink"
                                                style={{ fontSize: "3.5rem" }}
                                            />
                                            <br />
                                            <br />
                                            <span
                                                style={{
                                                    fontSize: "1.2rem",
                                                    color: "#468bff",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                정비예약 이력이 없으시네요!
                                            </span>
                                            <br />
                                            예약 가능한 업소를 찾아 예약 해보시는 건 어떠세요~?
                                        </div>
                                    </div>
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default ReservationList;
