//ie11 사용을 위한 설정 시작
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/stable";
import "regenerator-runtime/runtime";
//ie11 사용을 위한 설정 끝

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

//Redux 사용 설정 시작
import { Provider } from "react-redux";
import { store } from "./reducer";

//Redux 사용 설정 끝
ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,

  document.getElementById("root")
);

reportWebVitals();
