import { Tab } from "bootstrap";
import { useEffect, useState } from "react";
import { Tabs } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { areaCode, officeHour } from "../js/util";
import Page404 from "./comm/Page404";
import axios from "axios";
import {
    axiosConfig,
    debugMode,
    getClientIp,
    reqData,
    reqFormData,
    reqGeoData,
} from "../js/server";
import ConfigTab1 from "./ConfigTab1";
import ConfigTab2 from "./ConfigTab2";
import ConfigTab3 from "./ConfigTab3";
import ConfigTab4 from "./ConfigTab4";
import ConfigTab5 from "./ConfigTab5";
import queryString from "query-string";
import useComInfo from "../customHook/useComInfo";
import { SERVER_ERROR_MESSAGE } from "../initialState/comInfoInitialState";

function Config({ match, location, history }) {
    const dispatch = useDispatch();
    const query = queryString.parse(location.search);
    const comcode = match.params.comcode;
    const serviceKey = query.serviceKey;

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isModal, setModal] = useState(false); //daum postcode 관련 state
    const [isAuthCompany, setIsAuthCompany] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [previews, setPreviews] = useState([]);
    const [center, setCenter] = useState({
        lat: null,
        lng: null,
    });

    const [codeBrand, setCodeBrand] = useState([]);
    const [codeUrl, setCodeUrl] = useState([]);
    const [codeForte, setCodeForte] = useState([]);
    const [codeHoliday, setCodeHoliday] = useState([]);
    const [codeClose, setCodeClose] = useState([]);
    const [comInfo, updateComInfo, isModified, setInitialData, resetToInitialData] = useComInfo();

    //우편번호 Modal 닫기
    const handleClose = () => setModal(false);

    const setCominfoHandler = (e) => {
        const { id, value, type, maxLength } = e.target;

        if (type === "number" && value.length > maxLength) {
            updateComInfo({ [id]: value.substr(0, 8) });
        } else {
            updateComInfo({ [id]: value });
        }
    };

    const setHomePageHandler = (e, idx) => {
        const updatedHomePages = comInfo.jarr_homepage.map((page, index) => {
            if (index === idx) {
                return { ...page, target_url: e.target.value };
            }
            return page;
        });
        updateComInfo({ jarr_homepage: updatedHomePages });
    };

    const handleImageChange = (e) => {
        const filesToAdd = Array.from(e.target.files);
        let totalFiles = [...selectedFiles];

        for (let file of filesToAdd) {
            if ((comInfo.jarr_comphoto.length || 0) + totalFiles.length >= 10) {
                alert("사진은 최대 10장까지 첨부 가능합니다.");
                break;
            }
            totalFiles.push(file);
        }

        setSelectedFiles(totalFiles);
        setPreviews(totalFiles.map(file => ({
            ...file,
            preview: URL.createObjectURL(file),
        })));
    };

    //등록된 사진 서버에서 삭제
    const deleteRegistedFiles = async (item) => {
        if (window.confirm("서버에 등록된 업체 사진을 삭제합니다.\n\n삭제후 복구가 불가능합니다. 삭제하시겠습니까?")) {
            try {
                const jsonData = {
                    servicename: "ComPhotoDelete",
                    photo_idx: item.photo_idx,
                };

                const res = await reqData(jsonData);
                if (res.data.result === "OK") {
                    const updatedComPhotos = comInfo.jarr_comphoto.filter(
                        (photo) => photo.photo_idx !== item.photo_idx
                    );

                    updateComInfo({ jarr_comphoto: updatedComPhotos });
                } else {
                    alert("오류가 발생했습니다: " + res.data.errMsg);
                }
            } catch (err) {
                console.error("오류 발생: ", err);
                alert(SERVER_ERROR_MESSAGE);
            }
        }
    };

    //등록대기중인 사진 대기열에서 삭제
    const deleteSelectedFiles = (idx) => {
        const newFiles = selectedFiles.filter((_, i) => i !== idx);
        const newPreviews = previews.filter((_, i) => i !== idx);

        setSelectedFiles(newFiles);
        setPreviews(newPreviews);
    };

    const filterDataByKind = (data, kind) => data.filter(item => item.kind === kind);

    const fetchCodeItem = async () => {
        try {
            const jsonData = { servicename: "CodeSet" };

            const res = await reqData(jsonData);
            if (res.data.result === "OK") {
                const { jsonData } = res.data;

                setCodeBrand(filterDataByKind(jsonData, "BRAND"));
                setCodeUrl(filterDataByKind(jsonData, "COM_URL"));
                setCodeForte(filterDataByKind(jsonData, "FORTE"));
                setCodeHoliday(filterDataByKind(jsonData, "HOW_HOLIDAY"));
                setCodeClose(filterDataByKind(jsonData, "STOP_CLOSE"));
            } else {
                alert("오류가 발생했습니다: " + res.data.errMsg);
            }
        } catch (err) {
            console.error("오류 발생: ", err);
            alert(SERVER_ERROR_MESSAGE);
        } finally {
            fetchCominfo();
        }
    };

    const fetchCominfo = async () => {
        try {
            const jsonData = {
                servicename: "ShopView",
                comcode: comcode ?? comInfo.comcode,
                div: "config",
            };
            const res = await reqData(jsonData);
            if (res.data.result === "OK") {
                if (jsonData) {
                    setInitialData(res.data.jsonData);
                }
            } else {
                alert("정비맛집에 등록된 업체가 아닙니다. 고객센터에 문의하세요.");
            }
        } catch (err) {
            console.error("오류 발생: ", err);
            alert(SERVER_ERROR_MESSAGE);
        } finally {
            setTimeout(() => {
                dispatch({ type: "loadingChange", payload: false });
            }, 300);
        }
    };

    const setGeoLocation = (ip) => {
        const handleSuccess = (position) => {
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;

            // 기본 좌표와 일치하면 IP 기반 위치 조회
            if (lat === 35.907757 && lng === 127.766922) {
                naverGeoLocation(ip);
            } else {
                setCenter({ lat, lng });
                fetchCodeItem(); // 코드 항목 조회
            }
        };

        const handleError = () => {
            naverGeoLocation(ip); // 위치 조회 실패 시 IP 기반 위치 조회
        };

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
        } else {
            naverGeoLocation(ip);
        }
    };

    const getNaverGeoLocation = async (jsonData) => {
        const res = await axios.post(
            "https://trs.intravan.co.kr/Controllers/getGeoLocation",
            JSON.stringify(jsonData),
            axiosConfig
        );
        return res;
    }

    const naverGeoLocation = async (ip) => {
        try {
            const reqDate = { ip };
            const res = await getNaverGeoLocation(reqDate);
            if (res.data.result === "OK") {
                setCenter({
                    lat: res.data.lat,
                    lng: res.data.long,
                });

                fetchCodeItem();
            }
        } catch (err) {
            console.error("오류 발생: ", err);
            alert(SERVER_ERROR_MESSAGE);
        }
    };

    const naverMapClickHandler = (e) => {
        updateComInfo({ gis_x_coor: e.coord._lat, gis_y_coor: e.coord._lng });
    };

    const brandSwitchClick = () => {
        // "BRAND" 유형이 아닌 항목만 필터링
        const nonBrandSetcodes = comInfo.jarr_setcode.filter(item => item.kind !== "BRAND");

        // 업데이트된 setcode 배열로 comInfo 업데이트
        updateComInfo({ jarr_setcode: nonBrandSetcodes });
    };

    const forteCodeHandler = (e, item) => {
        const checked = e.target.checked;

        if (checked) {
            // 이미 배열에 있는지 확인
            const alreadyExists = comInfo.jarr_setcode.some(setcode => setcode.kind === "FORTE" && setcode.code === item.code);

            if (!alreadyExists) {
                updateComInfo({ jarr_setcode: [...comInfo.jarr_setcode, item] });
            }
        } else {
            // 체크 해제된 경우, 해당 항목을 제거
            const filteredSetcodes = comInfo.jarr_setcode.filter(setcode => !(setcode.kind === "FORTE" && setcode.code === item.code));

            updateComInfo({ jarr_setcode: filteredSetcodes });
        }
    };

    const setCodeHandler = (item, kind) => {
        const isItemExists = comInfo.jarr_setcode.some(setcode => setcode.kind === kind && setcode.code === item.code);

        let newSetcodes;

        if (kind === "HOW_HOLIDAY") {
            // 'HOW_HOLIDAY'는 하나만 유지해야 함
            newSetcodes = comInfo.jarr_setcode.filter(setcode => setcode.kind !== kind);

            if (!isItemExists) {
                newSetcodes.push(item);
            }
        } else {
            if (isItemExists) {
                // 이미 있는 항목 제거
                newSetcodes = comInfo.jarr_setcode.filter(setcode => !(setcode.kind === kind && setcode.code === item.code));
            } else {
                // 새로운 항목 추가
                newSetcodes = [...comInfo.jarr_setcode, item];
            }
        }

        updateComInfo({ jarr_setcode: newSetcodes });
    };

    // 유효성 검사 필드
    const validateFields = () => {
        const requiredFields = [
            { field: 'comname', message: '업체명은 필수 항목입니다.', focusId: 'comname' },
            { field: 'service_div', message: '업종은 필수 항목입니다.', focusId: 'service_div' },
            { field: 'tel0_0', message: '전화번호 하나 이상은 꼭 입력해 주세요.\n혹은 전화번호에 \'-\'이 들어가 있는지 확인해 주세요.', focusId: 'tel0_0' },
            { field: 'addr1', message: '주소는 필수 항목입니다.', focusId: 'addr' },
        ];

        for (const { field, message, focusId } of requiredFields) {
            if (comInfo[field] === "") {
                alert(message);
                document.getElementById(focusId).focus();
                return false;
            }
        }

        return true;
    };

    // 저장 버튼 선택시
    const saveClickHandler = () => {
        if (!validateFields()) return;

        if (window.confirm("저장하시겠습니까?")) {
            setIsSubmitting(true);
            dispatch({ type: "loadingChange", payload: true });

            if (selectedFiles.length > 0) {
                saveComPhoto();
            } else {
                saveCominfo();
            }
        }
    };

    const createFormData = () => {
        const formData = new FormData();
        selectedFiles.forEach((file, index) => {
            formData.append(`file${index}`, file, file.name);
        });
        formData.append("comcode", comInfo.comcode);
        return formData;
    };

    const saveComPhoto = async () => {
        const formData = createFormData();

        try {
            const res = await reqFormData(formData);
            if (res.data.result === "OK") {
                setSelectedFiles([]);
                setPreviews([]);
                saveCominfo();
            } else {
                alert("사진저장에 실패했습니다: " + res.data.errMsg);
            }
        } catch (err) {
            console.error("오류 발생: ", err);
            alert("사진 업로드 중 오류가 발생했습니다. 관리자에게 문의해주세요.");
        }
    };

    const saveCominfo = async () => {
        try {
            const jsonData = {
                servicename: "ShopSave",
                jsonData: comInfo,
            };
            const res = await reqData(jsonData);
            if (res.data.result === "OK") {
                fetchCominfo();
            } else {
                alert("고유코드 오류! 관리자에게 문의하세요!");
            }
        } catch (err) {
            console.error("오류 발생: ", err);
            alert(SERVER_ERROR_MESSAGE);
        } finally {
            setIsSubmitting(false);

            setTimeout(() => {
                dispatch({ type: "loadingChange", payload: false });
            }, 300);
        }
    };

    const addrToGeoConvertHandler = async (obj) => {
        try {
            const res = await reqGeoData(obj.addr1);
            if (res.data.documents) {
                const lat = res.data.documents[0].y;
                const lng = res.data.documents[0].x;

                setCenter({
                    lat,
                    lng,
                });
                obj.gis_x_coor = lat;
                obj.gis_y_coor = lng;
            }
        } catch (err) {
            console.error("오류 발생: ", err);
            alert(SERVER_ERROR_MESSAGE);
        } finally {
            updateComInfo(obj);
            setModal(false);
        }
    };

    // 탭을 변경할 때 호출되는 함수
    const handleTabChange = (key) => {
        if (isModified()) {
            if (window.confirm("변경된 내용이 있습니다. 저장하시겠습니까?")) {
                if (!validateFields()) return;

                setIsSubmitting(true);
                dispatch({ type: "loadingChange", payload: true });

                if (selectedFiles.length > 0) {
                    saveComPhoto();
                } else {
                    saveCominfo();
                }
            } else {
                // "아니오"를 선택한 경우, 초기 데이터로 되돌립니다.
                resetToInitialData();
            }
        }
    };

    const isAuthCheck = async () => {
        /*  if (debugMode) {
             setIsAuthCompany(true);
         } else { */
        try {
            const jsonData = {
                servicename: "ShopLuse",
                servicekey: serviceKey,
                comcode,
            };
            const res = await reqData(jsonData);
            if (res.data.result === "OK") {
                setIsAuthCompany(true);
            } else {
                setIsAuthCompany(false);
            }
        } catch (err) {
            console.error("오류 발생: ", err);
            alert(SERVER_ERROR_MESSAGE);
        } finally {
            setIsSubmitting(false);

            setTimeout(() => {
                dispatch({ type: "loadingChange", payload: false });
            }, 300);
        }
        /*} */
    };

    useEffect(() => {
        const fetchData = async () => {
            dispatch({ type: "loadingChange", payload: true });
            isAuthCheck();

            try {
                const res = await getClientIp();
                if (res) {
                    let data = res.data;
                    let myIp = data.match(new RegExp(`ip=(.*)\\n`));
                    if (myIp.length > 0) {
                        setGeoLocation(myIp[1]);
                    }
                }
            } catch (err) {
                console.error("오류 발생: ", err);
                alert(SERVER_ERROR_MESSAGE);
            }
        };

        fetchData();
        // eslint-disable-next-line
    }, []);

    return isAuthCompany ? (
        <div className="container">
            <div className="row">
                <div className="col-12 pt-5">
                    <p className="title-font b-700 blue-text tsize-xlarge">
                        정비맛집 노출정보 관리
                    </p>
                    우리 업소의 특장점을 잘 표현해 주세요. 특히 위치 정보는 유심히
                    봐주세요~
                </div>
            </div>

            <div className="row">
                <div className="col-12 pt-5">
                    <Tabs defaultActiveKey="tabIndex0" id="config-tabs" className="mb-3" onSelect={handleTabChange}>
                        <Tab eventKey="tabIndex0" title="기본정보">
                            <ConfigTab1
                                comInfo={comInfo}
                                setCominfoHandler={setCominfoHandler}
                                updateComInfo={updateComInfo}
                                areaCode={areaCode}
                                handleImageChange={handleImageChange}
                                previews={previews}
                                deleteSelectedFiles={deleteSelectedFiles}
                                isModal={isModal}
                                setModal={setModal}
                                handleClose={handleClose}
                                center={center}
                                naverMapClickHandler={naverMapClickHandler}
                                deleteRegistedFiles={deleteRegistedFiles}
                                isSubmitting={isSubmitting}
                                saveClickHandler={saveClickHandler}
                                addrToGeoConvertHandler={addrToGeoConvertHandler}
                                codeUrl={codeUrl}
                            />
                        </Tab>
                        <Tab eventKey="tabIndex1" title="부가정보">
                            <ConfigTab2
                                comInfo={comInfo}
                                brandSwitchClick={brandSwitchClick}
                                codeBrand={codeBrand}
                                setCodeHandler={setCodeHandler}
                                codeForte={codeForte}
                                forteCodeHandler={forteCodeHandler}
                                isSubmitting={isSubmitting}
                                saveClickHandler={saveClickHandler}
                                setHomePageHandler={setHomePageHandler}
                            />
                        </Tab>
                        <Tab eventKey="tabIndex2" title="휴무일 및 영업시간">
                            <ConfigTab3
                                codeClose={codeClose}
                                comInfo={comInfo}
                                setCominfoHandler={setCominfoHandler}
                                setCodeHandler={setCodeHandler}
                                codeHoliday={codeHoliday}
                                officeHour={officeHour}
                                isSubmitting={isSubmitting}
                                saveClickHandler={saveClickHandler} />
                        </Tab>
                        <Tab eventKey="tabIndex3" title="예약정보">
                            {comInfo.config_com === "1" ? (
                                <ConfigTab4
                                    comInfo={comInfo}
                                    updateComInfo={updateComInfo}
                                    isSubmitting={isSubmitting}
                                    saveClickHandler={saveClickHandler}
                                    setCominfoHandler={setCominfoHandler}
                                />
                            ) : (
                                <div className="row pt-9">
                                    <div className="col-12 text-center">
                                        <h1>
                                            <i className="fas fa-exclamation-triangle" />
                                        </h1>
                                        <br />
                                        <br />
                                        <h5>
                                            아쉽지만 예약 기능은 고객님께서 현재 사용하시는
                                            솔루션에서는 지원되지 않습니다.
                                            <br />
                                            <br />
                                            업그레이드 문의는 고객센터로 부탁드립니다.
                                        </h5>
                                        <br />
                                        <br />
                                        <h5>1522-3840</h5>
                                    </div>
                                </div>
                            )}
                        </Tab>
                        <Tab eventKey="tabIndex4" title="프로모션 관리">
                            {comInfo.config_com === "1" ? (
                                <ConfigTab5
                                    comInfo={comInfo}
                                    updateComInfo={updateComInfo}
                                    isSubmitting={isSubmitting}
                                    saveClickHandler={saveClickHandler}
                                />
                            ) : (
                                <div className="row pt-9">
                                    <div className="col-12 text-center">
                                        <h1>
                                            <i className="fas fa-exclamation-triangle" />
                                        </h1>
                                        <br />
                                        <br />
                                        <h5>
                                            아쉽지만 프로모션 관리 기능은 고객님께서 현재 사용하시는
                                            솔루션에서는 지원되지 않습니다.
                                            <br />
                                            <br />
                                            업그레이드 문의는 고객센터로 부탁드립니다.
                                        </h5>
                                        <br />
                                        <br />
                                        <h5>1522-3840</h5>
                                    </div>
                                </div>
                            )}
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    ) : (
        <Page404 />
    );
}

export default Config;
