import { useEffect } from "react";
import { useLocation } from "react-router";
import { removeCookie, setCookie } from "../../js/cookie";
import { debugMode, reqData } from "../../js/server";
import { getToday } from "../../js/util";

function KakaoCallback(props) {
    const { Kakao } = window;
    const location = useLocation();
    const KAKAO_ACCESS_CODE = location.search.split("=")[1];
    const KAKAO_REST_API_KEY = debugMode
        ? process.env.REACT_APP_KAKAO_DEV_REST_API_KEY
        : process.env.REACT_APP_KAKAO_REST_API_KEY
    const KAKAO_REDIRECT_URI = debugMode
        ? process.env.REACT_APP_KAKAO_DEV_REDIRECT_URI
        : process.env.REACT_APP_KAKAO_REDIRECT_URI

    const getKakaoToken = () => {
        fetch(
            `https://kauth.kakao.com/oauth/token?grant_type=authorization_code&client_id=${KAKAO_REST_API_KEY}&redirect_uri=${KAKAO_REDIRECT_URI}&code=${KAKAO_ACCESS_CODE}`,
            {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
            }
        )
            .then((res) => res.json())
            .then((data) => {
                if (data.access_token) {
                    localStorage.setItem("jmtLoginKind", "KAKAO");
                    localStorage.setItem("jmtToken", data.access_token);
                }

                Kakao.Auth.setAccessToken(data.access_token);
                Kakao.API.request({
                    url: "/v2/user/me",
                })
                    .then(function (res) {
                        localStorage.setItem("jmtLoginDate", getToday());
                        localStorage.setItem("jmtLoginAccount", res.kakao_account.email);

                        const userInfo = {
                            loginKind: "KAKAO",
                            token: localStorage.getItem("jmtToken"),
                            loginDate: localStorage.getItem("jmtLoginDate"),
                            loginAccount: localStorage.getItem("jmtLoginAccount"),
                        };

                        setCookie("jmtUserInfo", JSON.stringify(userInfo), {
                            path: "/",
                        });

                        const jsonData = {
                            servicename: "UserRegist",
                            platform: "KAKAO",
                            email: localStorage.getItem("jmtLoginAccount"),
                        };

                        reqData(jsonData)
                            .then((res) => {
                                //업체 안내를 통해 접속한 경우 로그인 후에도 업체로 돌아간다.
                                const callBackComcode = localStorage.getItem("callBackComcode");
                                if (callBackComcode)
                                    window.location.href = "/" + callBackComcode;
                                else window.location.href = "/";
                            })
                            .catch((err) => {
                                console.error(err);
                            });
                    })
                    .catch(function (error) {
                        console.log(error);
                        alert(
                            "로그인을 진행할 수 없습니다. 문제가 지속될 경우, 고객 지원팀으로 연락해 주시기 바랍니다."
                        );
                    });
            });
    };

    useEffect(() => {
        localStorage.removeItem("jmtLoginKind");
        localStorage.removeItem("jmtLoginAccount");
        localStorage.removeItem("jmtLoginDate");
        localStorage.removeItem("jmtToken");
        removeCookie("userinfo");

        if (!location.search) {
            alert("로그인에 실패하였습니다. 계속 문제 발생시 문의하세요.");
            window.location.href = "/";
            return;
        }
        getKakaoToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
}

export default KakaoCallback;
