import { getCookie } from "./cookie";

const getWorkState = (data) => {
    const d = new Date();
    const week = ["sun", "mon", "tue", "wed", "thur", "fri", "sat"];
    const todayWeek = week[d.getDay()];
    let todayStime = "",
        todayEtime = "";

    todayStime = data[todayWeek + "_stime"];
    todayEtime = data[todayWeek + "_etime"];

    const howHoliday = data.jarr_setcode.filter((item) => {
        return item.kind === "HOW_HOLIDAY";
    });

    const stopClose = data.jarr_setcode.filter((item) => {
        return item.kind === "STOP_CLOSE";
    });

    let result = "";
    if (todayStime && todayEtime) {
        result += todayStime + " ~ " + todayEtime;
    }

    result += result ? "<br />정기휴일 : " : "";
    for (let i = 0; i < stopClose.length; i++) {
        result += stopClose[i].codename + "요일<br />";
    }

    for (let i = 0; i < howHoliday.length; i++) {
        result += howHoliday[i].codename;
    }

    if (data.closed_today === "1") {
        result += "<br /><span class=\"red-text font-weight-bold\">금일 임시휴무</span><br />";
    }

    return result;
};

const getPhoneNumber = (data, detail = false) => {
    const tel0_0 = data.tel0_0;
    const tel1_0 = data.tel1_0;
    const tel0_1 = data.tel0_1;
    const tel1_1 = data.tel1_1;
    const tel0_2 = data.tel0_2;
    const tel1_2 = data.tel1_2;

    if (!detail) {
        if (tel1_0.length > 4) {
            return `${tel0_0}-${tel1_0.substr(0, tel1_0.length - 4)}-${tel1_0.substr(
                tel1_0.length - 4,
                4
            )}`;
        } else {
            return tel0_0 + "-" + tel1_0;
        }
    } else {
        let result = "";
        if (tel0_0 && tel1_0) {
            if (tel1_0.length > 4) {
                result += `${tel0_0}-${tel1_0.substr(
                    0,
                    tel1_0.length - 4
                )}-${tel1_0.substr(
                    tel1_0.length - 4,
                    4
                )}&nbsp;&nbsp;<a href="tel:${tel0_0}${tel1_0}">
            <span class="custom-btn-small blue-on">전화</span>
          </a><br />`;
            } else {
                result += tel0_0 + "-" + tel1_0 + "<br />";
            }
        }

        if (tel0_1 && tel1_1) {
            if (tel1_1.length > 4) {
                result +=
                    tel0_1 +
                    "-" +
                    tel1_1.substr(0, tel1_1.length - 4) +
                    "-" +
                    tel1_1.substr(tel1_1.length - 4, 4) +
                    "<br />";
            } else {
                result += tel0_1 + "-" + tel1_1 + "<br />";
            }
        }

        if (tel0_2 && tel1_2) {
            if (tel1_2.length > 4) {
                result +=
                    tel0_2 +
                    "-" +
                    tel1_2.substr(0, tel1_2.length - 4) +
                    "-" +
                    tel1_2.substr(tel1_2.length - 4, 4) +
                    "<br />";
            } else {
                result += tel0_2 + "-" + tel1_2 + "<br />";
            }
        }

        return result;
    }
};

const getHomepageCheck = (data) => {
    let result = false;

    for (let i = 0; i < data.length; i++) {
        if (data[i].target_url) {
            result = true;
            break;
        }
    }

    return result;
};

const getComPhotoSm = (data) => {
    const repPhoto =
        data.jarr_comphoto.length > 0
            ? data.jarr_comphoto[0].file_url
            : "../images/backgroundImage_m_hdpi.png";

    return repPhoto;
};

const getBrand = (data) => {
    const arrayBrand = data.jarr_setcode.filter((item) => {
        return item.kind === "BRAND";
    });

    if (arrayBrand.length > 0) return true;
    else return false;
};

const fetchBrand = (data) => {
    let result = "";
    const arrayBrand = data.jarr_setcode.filter((item) => {
        return item.kind === "BRAND";
    });

    for (let i = 0; i < arrayBrand.length; i++) {
        result +=
            '<img src="/images/icon/icon_b' + arrayBrand[i].code + '.png" alt="" />';
    }

    return result;
};

const getSkill = (data) => {
    const arrayBrand = data.jarr_setcode.filter((item) => {
        return item.kind === "FORTE";
    });

    if (arrayBrand.length > 0) return true;
    else return false;
};

const fetchSkill = (data) => {
    let result = "";
    const arraySkill = data.jarr_setcode.filter((item) => {
        return item.kind === "FORTE";
    });

    for (let i = 0; i < arraySkill.length; i++) {
        result += arraySkill[i].codename + " / ";
    }

    return result;
};

const fetchWorkingTimeInfo = (data) => {
    let result = "";
    const monWorkTime =
        data.mon_stime && data.mon_etime
            ? "월요일 : " + data.mon_stime + " ~ " + data.mon_etime
            : "";
    const tueWorkTime =
        data.tue_stime && data.tue_etime
            ? "화요일 : " + data.tue_stime + " ~ " + data.tue_etime
            : "";
    const wedWorkTime =
        data.wed_stime && data.wed_etime
            ? "수요일 : " + data.wed_stime + " ~ " + data.wed_etime
            : "";
    const thurWorkTime =
        data.thur_stime && data.thur_etime
            ? "목요일 : " + data.thur_stime + " ~ " + data.thur_etime
            : "";
    const friWorkTime =
        data.fri_stime && data.fri_etime
            ? "금요일 : " + data.fri_stime + " ~ " + data.fri_etime
            : "";
    const satWorkTime =
        data.sat_stime && data.sat_etime
            ? "토요일 : " + data.sat_stime + " ~ " + data.sat_etime
            : "";
    const sunWorkTime =
        data.sun_stime && data.sun_etime
            ? "일요일 : " + data.sun_stime + " ~ " + data.sun_etime
            : "";
    const breakTime =
        data.break_stime && data.break_etime
            ? "휴게시간 : " + data.break_stime + " ~ " + data.break_etime
            : "";

    const howHoliday = data.jarr_setcode.filter((item) => {
        return item.kind === "HOW_HOLIDAY";
    });

    const stopClose = data.jarr_setcode.filter((item) => {
        return item.kind === "STOP_CLOSE";
    });

    let arrStopClose = [];
    for (let i = 0; i < stopClose.length; i++) {
        arrStopClose.push(stopClose[i].codename);
    }

    if (monWorkTime) {
        if (arrStopClose.indexOf("월") < 0) result += monWorkTime + "<br />";
    }
    if (tueWorkTime) {
        if (arrStopClose.indexOf("화") < 0) result += tueWorkTime + "<br />";
    }
    if (wedWorkTime) {
        if (arrStopClose.indexOf("수") < 0) result += wedWorkTime + "<br />";
    }
    if (thurWorkTime) {
        if (arrStopClose.indexOf("목") < 0) result += thurWorkTime + "<br />";
    }
    if (friWorkTime) {
        if (arrStopClose.indexOf("금") < 0) result += friWorkTime + "<br />";
    }
    if (satWorkTime) {
        if (arrStopClose.indexOf("토") < 0) result += satWorkTime + "<br />";
    }
    if (sunWorkTime) {
        if (arrStopClose.indexOf("일") < 0) result += sunWorkTime + "<br />";
    }

    if (breakTime) {
        result += breakTime + "<br />";
    }
    if (stopClose) {
        result += "정기휴일 : " + arrStopClose.join(",") + "요일";
    }
    if (howHoliday.length > 0) {
        result += "&nbsp;(" + howHoliday[0].codename + ")<br />";
    }

    if (data.closed_today === "1") {
        result += "<span class=\"red-text font-weight-bold\">금일 임시휴무</span><br />";
    }

    return result;
};

const getDistanceFromLatLonInKm = (lat1, lng1, lat2, lng2) => {
    function deg2rad(deg) {
        return deg * (Math.PI / 180);
    }
    var r = 6371; //지구의 반지름(km)
    var dLat = deg2rad(lat2 - lat1);
    var dLon = deg2rad(lng2 - lng1);
    var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = r * c; // Distance in km

    return Math.round(d * 1000);
};

const cloneObj = (obj) => JSON.parse(JSON.stringify(obj));

const areaCode = [
    "02",
    "031",
    "032",
    "033",
    "041",
    "042",
    "043",
    "044",
    "050",
    "051",
    "052",
    "053",
    "054",
    "055",
    "061",
    "062",
    "063",
    "064",
    "010",
    "011",
    "016",
    "017",
    "018",
    "019",
    "0303",
    "0502",
    "0503",
    "0504",
    "0505",
    "0506",
    "0507",
    "0508",
    "070",
    "080",
    "1433",
    "1522",
    "1533",
    "1544",
    "1566",
    "1577",
    "1588",
    "1599",
    "1600",
    "1644",
    "1660",
    "1661",
    "1666",
    "1668",
    "1688",
    "1670",
    "1800",
    "1811",
    "1833",
    "1855",
    "1877",
    "1899",
];

const officeHour = [
    "01:00",
    "01:30",
    "02:00",
    "02:30",
    "03:00",
    "03:30",
    "04:00",
    "04:30",
    "05:00",
    "05:30",
    "06:00",
    "06:30",
    "07:00",
    "07:30",
    "08:00",
    "08:30",
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
    "21:00",
    "21:30",
    "22:00",
    "22:30",
    "23:00",
    "23:30",
    "00:00",
    "00:30",
];

const getToday = () => {
    const date = new Date();
    const year = date.getFullYear();
    let month = date.getMonth();
    month += 1;
    if (month <= 9) {
        month = "0" + month;
    }

    let day = date.getDate();
    if (day <= 9) {
        day = "0" + month;
    }

    return year + "-" + month + "-" + day;
};

const fetchUserInfo = () => {
    let result = {
        loginKind: null,
        token: null,
        loginDate: null,
        loginAccount: null,
    };

    if (getCookie("jmtUserInfo")) {
        result = getCookie("jmtUserInfo");
    }

    return result;
};

export const setTimeFormat = (time) => {
    return time.slice(0, 2) + ":" + time.slice(2);
}

export const formatDate = (dateString) => {
    const date = new Date(dateString);

    return new Intl.DateTimeFormat('en-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    }).format(date);
}

export const fnKakaoNaviClick = (item, nowPos) => {
    console.log(item);
    const isMobile = /Mobi/i.test(window.navigator.userAgent);
    const x = item.gis_x_coor;
    const y = item.gis_y_coor;
    const posInfo = {
        name: item.comname,
        x: Number(y),
        y: Number(x),
        coordType: "wgs84",
        rpOption: 100,
    };

    if (isMobile) {
        if (
            window.confirm(
                "카카오네비앱이 설치된 경우 목적지로 설정이 가능합니다.\n계속하시겠습니까?"
            )
        ) {
            try {
                window.Kakao.Navi.start(posInfo);
            } catch (e) {
                alert(e);

                if (
                    window.confirm(
                        "현재 카카오네비 연동에 문제가 있습니다.\n\n네이버지도앱이 설치된 경우 목적지로 설정이 가능합니다.\n계속하시겠습니까?"
                    )
                ) {
                    execNaverMap(
                        nowPos,
                        item.gis_x_coor,
                        item.gis_y_coor,
                        item.comname
                    );
                }
            }
        }
    } else {
        if (
            window.confirm(
                "카카오 지도를 이용하여 선택하신 업체를 목적지로 설정하시겠습니까?"
            )
        ) {
            window.open(
                `https://map.kakao.com/link/to/${item.comname},${item.gis_x_coor},${item.gis_y_coor}`,
                "kakao-map"
            );
        }
    }
};

export const fnNaverNaviClick = (item, nowPos) => {
    console.log(item);
    const isMobile = /Mobi/i.test(window.navigator.userAgent);

    if (isMobile) {
        if (
            window.confirm(
                "네이버지도앱이 설치된 경우 목적지로 설정이 가능합니다.\n계속하시겠습니까?"
            )
        ) {
            execNaverMap(nowPos, item.gis_x_coor, item.gis_y_coor, item.comname);
        }
    } else {
        if (
            window.confirm(
                "네이버 길찾기는 데스크탑에서 제공되지 않습니다.\n카카오 지도를 이용하여 선택하신 업체를 목적지로 설정하시겠습니까?"
            )
        ) {
            window.open(
                `https://map.kakao.com/link/to/${item.comname},${item.gis_x_coor},${item.gis_y_coor}`,
                "kakao-map"
            );
        }
    }
};

const execNaverMap = (nowPos, dlat, dlng, dname, urlName) => {
    const slat = nowPos.lat;
    const slng = nowPos.lng;
    const sname = "출발지";

    const link = document.createElement('a');
    link.href = `nmap://route/car?slat=${slat}&slng=${slng}&sname=${encodeURI(sname)}&dlat=${dlat}&dlng=${dlng}&dname=${encodeURI(dname)}&appname=${urlName}`;
    link.target = '_self';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export {
    getWorkState,
    getPhoneNumber,
    getBrand,
    fetchBrand,
    getSkill,
    fetchSkill,
    fetchWorkingTimeInfo,
    getDistanceFromLatLonInKm,
    cloneObj,
    areaCode,
    officeHour,
    getHomepageCheck,
    getComPhotoSm,
    getToday,
    fetchUserInfo,
};

