import React, { useEffect } from "react";
import ResponsiveGallery from "react-responsive-gallery";

import {
    fetchBrand,
    fetchSkill,
    fetchWorkingTimeInfo,
    fnKakaoNaviClick,
    fnNaverNaviClick,
    getBrand,
    getHomepageCheck,
    getPhoneNumber,
    getSkill,
} from "../js/util";
import { debugMode, urlName } from "../js/server";
import { useDispatch } from "react-redux";
import { NOT_LOGIN_MESSAGE } from "../initialState/comInfoInitialState";

function ComDetailInfo(props) {
    const dispatch = useDispatch();

    const fnReservClick = () => {
        const comname = props.selectedCompanyByList.comname;
        const comcode = props.selectedCompanyByList.comcode;
        const prgcode = props.selectedCompanyByList.prgcode;
        const platform = props.userInfo.loginKind;
        const email = props.userInfo.loginAccount;

        if (email && platform) {
            if (window.confirm(`[${comname}]에 정비 예약 해보시겠어요?\n예약 후 취소는 업체로 연락주셔야 됩니다~`)) {
                if (debugMode) {
                    window.location.href = `http://adbcp.goldauto.co.kr/Self/reservation.aspx?comcode=${comcode}&prgcode=${prgcode}&platform=${platform}&email=${email}`;
                    return;
                }

                window.location.href = `https://adbcp.intravan.co.kr/Self/reservation.aspx?comcode=${comcode}&prgcode=${prgcode}&platform=${platform}&email=${email}`;
            }

            return;
        }

        window.alert(NOT_LOGIN_MESSAGE);
        props.showTooltipHandler();
    };

    useEffect(() => {
        dispatch({ type: "SET_BOTTOM_SHEET_STATE", payload: { isOpen: true, currentState: 'detail-info' } });
        window.history.pushState({ sheetState: 'detail-info' }, '', `#${'detail-info'}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="btn-bottom-sheet-drop">
                <img
                    src="/images/ico_close02_xhdpi.png"
                    alt=""
                    onClick={props.unSelectCompany}
                />
            </div>
            <div className="main-cominfo-default-wrapper">
                <ul>
                    <li className="pb-1">
                        <div>
                            <p className="blue-text b-700 tsize-large">
                                {props.myComItem ? (
                                    props.myComItem
                                        .map((item) => {
                                            return item["comcode"];
                                        })
                                        .indexOf(props.selectedCompanyByList.comcode) > -1 ? (
                                        <>
                                            {props.selectedCompanyByList.comname}{" "}
                                            <img
                                                src="../../images/icon_pin_xhdpi.png"
                                                alt=""
                                                className="icon-18"
                                            />
                                        </>
                                    ) : (
                                        props.selectedCompanyByList.comname
                                    )
                                ) : (
                                    props.selectedCompanyByList.comname
                                )}
                            </p>
                            {props.selectedCompanyByList.service_div || ""}
                        </div>
                        <div>
                            <br />
                            {props.selectedCompanyByList.set_reserv === "1" ? (
                                <span
                                    className="custom-btn blue"
                                    style={{ cursor: "pointer" }}
                                    onClick={fnReservClick}
                                >
                                    정비예약
                                </span>
                            ) : null}
                        </div>
                    </li>
                    <li className="pb-1">
                        <div>업체정보</div>
                        {props.myComItem ? (
                            props.myComItem
                                .map((item) => {
                                    return item["comcode"];
                                })
                                .indexOf(props.selectedCompanyByList.comcode) > -1 ? (
                                <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                        props.myComItemHandler(
                                            props.selectedCompanyByList.comcode,
                                            "Y"
                                        )
                                    }
                                >
                                    내 정비맛집 해제
                                </div>
                            ) : (
                                <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                        props.myComItemHandler(
                                            props.selectedCompanyByList.comcode,
                                            ""
                                        )
                                    }
                                >
                                    내 정비맛집 등록
                                </div>
                            )
                        ) : (
                            <div
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                    props.myComItemHandler(
                                        props.selectedCompanyByList.comcode,
                                        ""
                                    )
                                }
                            >
                                내 정비맛집 등록
                            </div>
                        )}
                    </li>
                    <li className="pb-1">
                        <table className="cominfo-list">
                            <tbody>
                                {
                                    (props.selectedCompanyByList.jarr_promotion?.length > 0) &&
                                    <tr onClick={() => {
                                        props.setSelectedPromotion(props.selectedCompanyByList.jarr_promotion[0]);
                                        props.setSelectedPromotionComName(props.selectedCompanyByList.comname);
                                        props.setIsPromotion(true);
                                    }} style={{ cursor: 'pointer' }}>
                                        <td className="pb-3">
                                            <i className="fa-duotone fa-calendar-star text-danger font-weight-bold" />
                                        </td>
                                        <td className="pb-3">
                                            <span className="prom-text font-weight-bold">{
                                                props.selectedCompanyByList.jarr_promotion[0]?.prom_title?.length > 30
                                                    ? props.selectedCompanyByList.jarr_promotion[0]?.prom_title.slice(0, 28) + "..."
                                                    : props.selectedCompanyByList.jarr_promotion[0]?.prom_title
                                            }</span>
                                        </td>
                                    </tr>
                                }
                                <tr>
                                    <td className="pb-3">
                                        <img
                                            src="../images/ico_map_xhdpi.png"
                                            alt="ico_map"
                                            className="cominfo-icon"
                                        />
                                    </td>
                                    <td className="pb-3">
                                        {props.selectedCompanyByList.address || ""}
                                        <br />
                                        {props.selectedCompanyByList.further_map || ""}
                                    </td>
                                </tr>

                                {getBrand(props.selectedCompanyByList) ? (
                                    <tr>
                                        <td className="pb-3">
                                            <img
                                                src="../images/ico_brand_xhdpi.png"
                                                alt="ico_brand"
                                                className="cominfo-icon"
                                            />
                                        </td>
                                        <td
                                            className="pb-3 user-brand-icon"
                                            dangerouslySetInnerHTML={{
                                                __html: fetchBrand(props.selectedCompanyByList) || "",
                                            }}
                                        ></td>
                                    </tr>
                                ) : null}

                                {getSkill(props.selectedCompanyByList) ? (
                                    <tr>
                                        <td className="pb-3">
                                            <img
                                                src="../images/ico_skill_xhdpi.png"
                                                alt="ico_skill"
                                                className="cominfo-icon"
                                            />
                                        </td>
                                        <td className="pb-3">
                                            {fetchSkill(props.selectedCompanyByList)}
                                        </td>
                                    </tr>
                                ) : null}

                                <tr>
                                    <td className="pb-3">
                                        <img
                                            src="../images/ico_clock_xhdpi.png"
                                            alt="ico_clock"
                                            className="cominfo-icon"
                                        />
                                    </td>
                                    <td
                                        className="pb-3"
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                fetchWorkingTimeInfo(props.selectedCompanyByList) || "",
                                        }}
                                    ></td>
                                </tr>

                                <tr>
                                    <td className="pb-3">
                                        <img
                                            src="../images/ico_call02_xhdpi.png"
                                            alt="ico_call02"
                                            className="cominfo-icon"
                                        />
                                    </td>
                                    <td
                                        className="pb-3"
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                getPhoneNumber(props.selectedCompanyByList, true) || "",
                                        }}
                                    ></td>
                                </tr>

                                {props.selectedCompanyByList.intro ? (
                                    <tr>
                                        <td className="pb-3">
                                            <img
                                                src="../images/ico_explain_xhdpi.png"
                                                alt="ico_explain"
                                                className="cominfo-icon"
                                            />
                                        </td>
                                        <td className="pb-3">
                                            {props.selectedCompanyByList.intro
                                                .split("\n")
                                                .map((line, idx) => {
                                                    return (
                                                        <span key={idx}>
                                                            {line}
                                                            <br />
                                                        </span>
                                                    );
                                                }) || ""}
                                        </td>
                                    </tr>
                                ) : null}

                                {getHomepageCheck(props.selectedCompanyByList.jarr_homepage) ? (
                                    <tr className="pb-3">
                                        <td>
                                            <img
                                                src="../images/ico_bookmark_xhdpi.png"
                                                alt="ico_bookmark"
                                                className="cominfo-icon"
                                            />
                                        </td>
                                        <td>
                                            {props.selectedCompanyByList.jarr_homepage.map(
                                                (item, idx) => {
                                                    return item.target_url ? (
                                                        <a href={item.target_url} target="new" key={idx}>
                                                            <span className="custom-btn-small purple-on">
                                                                {item.codename || ""}
                                                            </span>
                                                        </a>
                                                    ) : null;
                                                }
                                            )}
                                        </td>
                                    </tr>
                                ) : null}

                                <tr>
                                    <td></td>
                                    <td className="pt-3 pb-3">
                                        <img
                                            className="icon-48"
                                            src="../images/icon/icon-kakao-navi.png"
                                            alt=""
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                                fnKakaoNaviClick(
                                                    props.selectedCompanyByList,
                                                    props.location.currentLocation
                                                )
                                            }
                                        />

                                        <img
                                            className="icon-48"
                                            src="../images/icon/icon-naver-map.png"
                                            alt=""
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                                fnNaverNaviClick(
                                                    props.selectedCompanyByList,
                                                    props.location.currentLocation
                                                )
                                            }
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </li>
                </ul>

                <div className="cominfo-detail-photo-wrapper">
                    {/* <Gallery photos={props.photos} onClick={openLightbox} /> */}
                    <ResponsiveGallery
                        media={props.photos}
                        useLightBox
                        numOfMediaPerRow={{
                            xs: 2,
                            s: 3,
                            m: 3,
                            l: 3,
                            xl: 3,
                            xxl: 3
                        }}
                    />
                </div>

                <div className="btn-back-wrapper">
                    <p
                        className="btn-more mx-5"
                        onClick={props.unSelectCompany}
                        style={{ margin: "0 auto" }}
                    >
                        뒤로가기
                        <img src="../images/ico_arrow01.png" alt="" />
                    </p>
                </div>
            </div>
        </>
    );
}

export default ComDetailInfo;
