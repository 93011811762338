import "./Loading.css";

function Loading(props) {
    const fadeStyle = props.loading ? "fadeIn" : "fadeOut";

    return (
        <div className={`loading-container ${fadeStyle}`}>
            <div className="loader"></div>
        </div>
    );
}

export default Loading;
