import axios from "axios";
import { useEffect, useState } from "react";

export const useAxios = (opts) => {
    const [state, setState] = useState({ loading: false, error: null, res: null });
    const [trigger, setTrigger] = useState(0);

    const refetch = (newOpts) => {
        if (!state.loading) {
            setState({ ...state, loading: true });
            setTrigger({ timestamp: Date.now(), options: { ...opts, ...newOpts } });
        }
    };

    useEffect(() => {
        if (!trigger.options || trigger.timestamp === 0) {
            return;
        }

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
        };
        const axiosInstance = axios.create({ headers });
        axiosInstance(trigger.options)
            .then((data) => {
                setState({ ...state, loading: false, res: data });
            })
            .catch((error) => {
                setState({ ...state, loading: false, error });
            });
        // eslint-disable-next-line
    }, [trigger]);

    return { ...state, refetch };
};