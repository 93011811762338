import Postcode from "@actbase/react-daum-postcode";
import { Modal } from "react-bootstrap";
import { cloneObj } from "../js/util";
import { Marker, NaverMap } from "react-naver-maps";
import { useRef } from "react";

function ConfigTab1(props) {
    const naverRef = useRef();
    const navermaps = window.naver.maps;

    return (
        <>
            <div className="row pt-4">
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="comname" className="config-title">
                            정비맛집 노출을 원치 않으시나요? (추후 더 많은 기능을 제공할
                            예정입니다~)
                        </label>
                        <div className="custom-control custom-switch">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="luse"
                                checked={props.comInfo.luse === "1"}
                                onChange={(e) => {
                                    props.updateComInfo({ luse: e.target.checked ? "1" : "0" });
                                }}
                            />
                            {props.comInfo.luse === "1" ? (
                                <label
                                    className="custom-control-label blue-text"
                                    htmlFor="luse"
                                    id="luseLabel"
                                >
                                    정비맛집에 노출되어 더 많은 고객이 방문하길 원합니다!
                                </label>
                            ) : (
                                <label
                                    className="custom-control-label"
                                    htmlFor="luse"
                                    id="luseLabel"
                                >
                                    아쉽지만 정비맛집에 노출되지 않길 바랍니다.
                                </label>
                            )}
                        </div>
                        <small id="luseHelp" className="form-text blue-text">
                            * 필수
                        </small>
                    </div>
                </div>
            </div>

            <div className="row pt-4">
                <div className="col-6">
                    <div className="form-group">
                        <label htmlFor="comname" className="config-title">
                            업체명을 입력해 주세요.
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="comname"
                            aria-describedby="comnameHelp"
                            maxLength={50}
                            value={props.comInfo.comname || ""}
                            onChange={props.setCominfoHandler}
                        />
                        <small id="comnameHelp" className="form-text blue-text">
                            * 필수
                        </small>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <label htmlFor="service_div" className="config-title">
                            업종을 입력해 주세요.
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="service_div"
                            aria-describedby="service_divHelp"
                            maxLength={25}
                            value={props.comInfo.service_div || ""}
                            onChange={props.setCominfoHandler}
                        />
                        <small id="service_divHelp" className="form-text blue-text">
                            * 필수
                        </small>
                    </div>
                </div>
            </div>

            <div className="row pt-4">
                <div className="col-4">
                    <div className="form-group">
                        <label htmlFor="tel0_0" className="config-title">
                            고객이 바로 연결 가능한 연락처를 입력해 주세요.
                        </label>
                        <div className="input-group">
                            <select
                                type="text"
                                className="form-control"
                                id="tel0_0"
                                aria-describedby="tel0_0Help"
                                value={props.comInfo.tel0_0 || ""}
                                onChange={props.setCominfoHandler}
                            >
                                {props.areaCode.map((item, idx) => {
                                    return (
                                        <option key={idx} value={item}>
                                            {item}
                                        </option>
                                    );
                                })}
                            </select>
                            <div className="input-group-append">
                                <input
                                    type="number"
                                    className="form-control"
                                    id="tel1_0"
                                    aria-describedby="tel1_0Help"
                                    maxLength={8}
                                    value={props.comInfo.tel1_0 || ""}
                                    onChange={props.setCominfoHandler}
                                />
                            </div>
                        </div>
                        <small id="tel0_0Help" className="form-text blue-text">
                            * 필수 (숫자만 입력해 주세요.)
                        </small>
                    </div>
                </div>
                <div className="col-4">
                    <div className="form-group">
                        <label htmlFor="service_div" className="config-title">
                            연락처가 더 있으시다면 입력해 주세요.
                        </label>
                        <div className="input-group">
                            <select
                                type="text"
                                className="form-control"
                                id="tel0_1"
                                value={props.comInfo.tel0_1 || ""}
                                onChange={props.setCominfoHandler}
                            >
                                {props.areaCode.map((item, idx) => {
                                    return (
                                        <option key={idx} value={item}>
                                            {item}
                                        </option>
                                    );
                                })}
                            </select>
                            <div className="input-group-append">
                                <input
                                    type="number"
                                    className="form-control"
                                    id="tel1_1"
                                    maxLength={8}
                                    value={props.comInfo.tel1_1 || ""}
                                    onChange={props.setCominfoHandler}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="form-group">
                        <label htmlFor="service_div" className="config-title">
                            연락처는 총 3개까지 입력이 가능합니다.
                        </label>
                        <div className="input-group">
                            <select
                                type="text"
                                className="form-control"
                                id="tel0_2"
                                value={props.comInfo.tel0_2 || ""}
                                onChange={props.setCominfoHandler}
                            >
                                {props.areaCode.map((item, idx) => {
                                    return (
                                        <option key={idx} value={item}>
                                            {item}
                                        </option>
                                    );
                                })}
                            </select>
                            <div className="input-group-append">
                                <input
                                    type="number"
                                    className="form-control"
                                    id="tel1_2"
                                    maxLength={8}
                                    value={props.comInfo.tel1_2 || ""}
                                    onChange={props.setCominfoHandler}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row pt-4">
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="comphoto" className="config-title">
                            우리 업체를 소개할 수 있는 사진을 등록해 주세요.
                        </label>
                        <br />
                        <input
                            type="file"
                            id="imgFile"
                            className="hidden"
                            onChange={props.handleImageChange}
                            multiple={true}
                            accept="image/gif, image/jpeg, image/png"
                        />
                        <label htmlFor="imgFile">
                            <div className="file-input-custom">
                                <i className="far fa-image" />
                                <p>사진 추가</p>
                            </div>
                        </label>
                    </div>
                </div>

                <div className="col-12">
                    <ul className="preview-wrapper">
                        {props.comInfo?.jarr_comphoto?.map((img, idx) => {
                            return (
                                <li key={idx}>
                                    <button
                                        className="preview-delete-icon btn btn-primary"
                                        onClick={() => props.deleteRegistedFiles(img)}
                                    >
                                        등록완료&nbsp;
                                        <i className="far fa-trash-alt" />
                                    </button>
                                    <img src={img.file_url} alt="" />
                                </li>
                            );
                        })}
                        {props.previews.map((img, idx) => {
                            return (
                                <li key={idx}>
                                    <button
                                        className="preview-delete-icon btn btn-secondary"
                                        onClick={() => props.deleteSelectedFiles(idx)}
                                    >
                                        등록대기중&nbsp;
                                        <i className="far fa-trash-alt" />
                                    </button>
                                    <img src={img.preview} alt="" />
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>

            <div className="row pt-4">
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="intro" className="config-title">
                            간단한 업체소개를 입력해 주세요.
                        </label>
                        <textarea
                            className="form-control"
                            id="intro"
                            maxLength={1000}
                            value={props.comInfo.intro || ""}
                            onChange={props.setCominfoHandler}
                            placeholder="(주)인트라밴은 1991년부터 자동차 정비 관련 소프트웨어 개발 전문~"
                        />
                    </div>
                </div>
            </div>

            <div className="row pt-4">
                <div className="col-6">
                    <Modal show={props.isModal} onHide={props.handleClose}>
                        <Postcode
                            jsOptions={{ animation: true, hideMapBtn: true }}
                            onSelected={(data) => {
                                let copiedObj = cloneObj(props.comInfo);
                                copiedObj["addr1"] = data.address;
                                props.addrToGeoConvertHandler(copiedObj);
                            }}
                        />
                    </Modal>

                    <div className="form-group">
                        <label htmlFor="addr1" className="config-title">
                            주소는 주소찾기 기능을 이용하여 입력해 주세요.
                        </label>
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control"
                                id="addr1"
                                aria-describedby="addr1Help"
                                maxLength={50}
                                value={props.comInfo.addr1 || ""}
                                onChange={props.setCominfoHandler}
                                readOnly={true}
                            />
                            <div className="input-group-append">
                                <button
                                    className="btn btn-outline-secondary"
                                    type="button"
                                    onClick={() => props.setModal(true)}
                                >
                                    주소찾기&nbsp;&nbsp;
                                    <i className="far fa-address-book" />
                                </button>
                            </div>
                        </div>
                        <small id="addr1Help" className="form-text blue-text">
                            * 필수
                        </small>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <label htmlFor="addr2" className="config-title">
                            나머지 주소를 입력해 주세요.
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="addr2"
                            aria-describedby="addr2Help"
                            maxLength={25}
                            value={props.comInfo.addr2 || ""}
                            onChange={props.setCominfoHandler}
                        />
                    </div>
                </div>
            </div>

            <div className="row pt-4">
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="comphoto" className="config-title">
                            업체 위치를 확인해 주세요. 정비맛집 지도에 노출될 위치입니다.
                        </label>
                        <br />
                        <input
                            type="text"
                            id="gis_x_coor"
                            readOnly={true}
                            size="10"
                            placeholder="위도"
                            value={props.comInfo.gis_x_coor || ""}
                            onChange={props.setCominfoHandler}
                        />
                        &nbsp;
                        <input
                            type="text"
                            id="gis_y_coor"
                            readOnly={true}
                            size="10"
                            placeholder="경도"
                            value={props.comInfo.gis_y_coor || ""}
                            onChange={props.setCominfoHandler}
                        />
                    </div>
                </div>

                <div className="col-12">
                    <NaverMap
                        ref={naverRef}
                        id="config-maps"
                        defaultZoom={18}
                        center={
                            props.comInfo.gis_x_coor && props.comInfo.gis_y_coor
                                ? {
                                    lat: props.comInfo.gis_x_coor,
                                    lng: props.comInfo.gis_y_coor,
                                }
                                : props.center
                        }
                        onClick={props.naverMapClickHandler}
                    >
                        {props.comInfo.gis_x_coor && props.comInfo.gis_y_coor ? (
                            <Marker
                                position={
                                    new navermaps.LatLng(
                                        props.comInfo.gis_x_coor,
                                        props.comInfo.gis_y_coor
                                    )
                                }
                                icon={{
                                    content: `<img src="../../images/ico_result_o_mdpi.png" />`,
                                    size: new navermaps.Size(48, 48),
                                    anchor: new navermaps.Point(20, 46), // 지도상 위치에서 이미지 위치의 offset값
                                }}
                                title={props.comInfo.comname}
                            />
                        ) : null}
                    </NaverMap>
                </div>
            </div>

            <div className="row pt-5">
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="intro" className="config-title">
                            찾아오는 길 추가 설명이 필요하신가요?
                        </label>
                        <textarea
                            className="form-control"
                            id="further_map"
                            maxLength={1000}
                            value={props.comInfo.further_map || ""}
                            onChange={props.setCominfoHandler}
                            placeholder="OO역 3번 출구에서 내려서 200m 전방에 위치"
                        />
                    </div>
                </div>
            </div>

            <div className="row pt-5 pb-99">
                <div className="col-12">
                    <button
                        className="col-12 btn btn-secondary"
                        disabled={props.isSubmitting}
                        onClick={props.saveClickHandler}
                    >
                        저장
                    </button>
                </div>
            </div>
        </>
    );
}

export default ConfigTab1;
