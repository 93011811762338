import React, { useEffect, useState } from 'react'
import { SERVER_ERROR_MESSAGE } from '../initialState/comInfoInitialState';
import { reqData } from '../js/server';
import PromotionModal from './comm/PromotionModal'

function ConfigTab5(props) {
    const [modalShow, setModalShow] = useState(false);
    const [promotionPosts, setPromotionPosts] = useState([]);
    const [promotionData, setPromotionData] = useState({
        serial: "",
        subject: "",
        contents: "",
        eventStartDate: new Date(),
        eventEndDate: new Date(),
        selectedFiles: [],
        previews: [],
        uploadedFiles: []
    });

    //등록대기중인 사진 대기열에서 삭제
    const deletePromotionSelectedFiles = (idx) => {
        const newFiles = promotionData.selectedFiles.filter((_, i) => i !== idx);
        const newPreviews = promotionData.previews.filter((_, i) => i !== idx);

        setPromotionData((prev) => (
            {
                ...prev,
                selectedFiles: newFiles,
                previews: newPreviews
            }
        ));
    };

    const handlePromotionImageChange = (e) => {
        const filesToAdd = Array.from(e.target.files);
        let totalFiles = [...promotionData.selectedFiles];

        for (let file of filesToAdd) {
            if ((promotionData.uploadedFiles.length || 0) + totalFiles.length >= 10) {
                alert("사진은 최대 10장까지 첨부 가능합니다.");
                break;
            }
            totalFiles.push(file);
        }

        setPromotionData((prev) => (
            {
                ...prev,
                selectedFiles: totalFiles,
                previews: totalFiles.map(file => ({
                    ...file,
                    preview: URL.createObjectURL(file),
                }))
            }
        ));
    };

    const fetchData = async () => {
        const jsonData = {
            "servicename": "Promotion",
            "comcode": props.comInfo.comcode,
        }

        try {
            const res = await reqData(jsonData);
            if (res.data.result === "OK") {
                setPromotionPosts(res.data.jarr_promotion);
            } else {
                alert("고유코드 오류! 관리자에게 문의하세요!");
            }
        } catch (err) {
            console.error("오류 발생: ", err);
            alert(SERVER_ERROR_MESSAGE);
        }
    }

    const itemClick = (item) => {
        setPromotionData({
            serial: item.prom_idx,
            subject: item.prom_title,
            contents: item.prom_memo,
            eventStartDate: item.prom_sday,
            eventEndDate: item.prom_eday,
            selectedFiles: [],
            previews: [],
            uploadedFiles: item.prom_photo
        });
        setModalShow(true);
    }

    const deleteRegistedFiles = async (item) => {
        if (window.confirm("서버에 등록된 업체 사진을 삭제합니다.\n\n삭제후 복구가 불가능합니다. 삭제하시겠습니까?")) {
            const jsonData = {
                servicename: "PromotionPhotoDelete",
                photo_idx: item.photo_idx,
            };

            try {
                const res = await reqData(jsonData);
                if (res.data.result === "OK") {
                    const updatedComPhotos = promotionData.uploadedFiles.filter(
                        (photo) => photo.photo_idx !== item.photo_idx
                    );

                    setPromotionData((prev) => ({
                        ...prev,
                        uploadedFiles: updatedComPhotos
                    }));
                    fetchData();
                } else {
                    alert("오류가 발생했습니다: " + res.data.errMsg);
                }
            } catch (err) {
                console.error("오류 발생: ", err);
                alert(SERVER_ERROR_MESSAGE);
            }
        }
    };


    const deleteClick = async () => {
        const jsonData = {
            servicename: "PromotionDelete",
            comcode: props.comInfo.comcode,
            prom_idx: promotionData.serial
        };

        if (!promotionData.serial) {
            return;
        }

        if (window.confirm("삭제후 복구는 불가능합니다.\n진행하시겠습니까?")) {
            try {
                const res = await reqData(jsonData);
                if (res.data.result === "OK") {
                    fetchData();
                    setPromotionData({
                        serial: "",
                        subject: "",
                        contents: "",
                        eventStartDate: new Date(),
                        eventEndDate: new Date(),
                        selectedFiles: [],
                        previews: [],
                        uploadedFiles: []
                    });
                    setModalShow(false);
                } else {
                    alert("오류가 발생했습니다: " + res.data.errMsg);
                }
            } catch (err) {
                console.error("오류 발생: ", err);
                alert(SERVER_ERROR_MESSAGE);
            }
        }
    }

    useEffect(() => {
        if (!props.comInfo.comcode) {
            return;
        }

        fetchData();
    }, [props.comInfo.comcode])

    return (
        <>
            <div className="row pt-4">
                <div className="col-12">
                    <div className="form-group">
                        <span className="config-title">계획중인 프로모션이 있으신가요?</span>
                        <br />
                        * 프로모션 등록 후 SMS를 이용하여 고객분들께 이벤트 진행중이라는 문구와 함께 정비맛집 업체 위치 전송 문자를 보내보세요!
                        <br />
                        &nbsp;&nbsp;&nbsp;진행중인 프로모션은 하나만 가능하며 일정이 중복 되는 경우 가장 최근에 등록된 프로모션이 노출됩니다.
                    </div>
                </div>
            </div>

            <div className="row pt-4">
                <div className="col-12 text-left">
                    <button
                        className="btn btn-outline-secondary pr-2 pl-2"
                        onClick={() => setModalShow(true)}
                    >
                        <i className="fa-sharp fa-solid fa-pen pr-2" />신규
                    </button>
                </div>
            </div>

            <div className="row pt-3">
                <div className="col-12">
                    <table className="table table-hover promotion-table">
                        <thead>
                            <tr>
                                <th scope="col" style={{ width: "25%" }}>
                                    <i className="fa-light fa-calendar pr-2" />게시기간
                                </th>
                                <th scope="col">
                                    <i className="fa-light fa-memo pr-2" />제목
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                promotionPosts.length > 0
                                    ? promotionPosts.map((item, idx) => {
                                        return (
                                            <tr key={idx} onClick={(e) => itemClick(item)}>
                                                <td>
                                                    {item.prom_sday}~{item.prom_eday}
                                                </td>
                                                <td>
                                                    {item.prom_title}
                                                </td>
                                            </tr>
                                        )
                                    })
                                    : <tr>
                                        <td colSpan={2}>현재 진행 중인 프로모션이 없습니다.<br />다양한 프로모션을 통해 고객을 매료시켜 보세요.</td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            <PromotionModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                comInfo={props.comInfo}
                promotionData={promotionData}
                setPromotionData={setPromotionData}
                deletePromotionSelectedFiles={deletePromotionSelectedFiles}
                handlePromotionImageChange={handlePromotionImageChange}
                fetchData={fetchData}
                deleteRegistedFiles={deleteRegistedFiles}
                deleteClick={deleteClick}
            />
        </>
    )
}

export default ConfigTab5