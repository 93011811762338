import { debugMode } from "../../js/server";

function KakaoLogin(props) {
    const KAKAO_REST_API_KEY = debugMode
        ? process.env.REACT_APP_KAKAO_DEV_REST_API_KEY
        : process.env.REACT_APP_KAKAO_REST_API_KEY
    const KAKAO_REDIRECT_URI = debugMode
        ? process.env.REACT_APP_KAKAO_DEV_REDIRECT_URI
        : process.env.REACT_APP_KAKAO_REDIRECT_URI
    const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${KAKAO_REST_API_KEY}&redirect_uri=${KAKAO_REDIRECT_URI}&response_type=code`;

    const handleLogin = () => {
        window.location.href = KAKAO_AUTH_URL;
    };
    return (
        <>
            <div id="kakaoIdLogin">
                <img
                    src="../../images/sns-btn/btn-kakao.png"
                    alt="kakao"
                    style={{ height: "50px", cursor: "pointer" }}
                    onClick={() => handleLogin()}
                />
            </div>
        </>
    );
}

export default KakaoLogin;
